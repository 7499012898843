// import { combineReducers } from "@reduxjs/toolkit";
// import {
//   caseReducer,
//   incidentReducer,
//   narrativeReducer,
//   suspectReducer,
//   witnessesReducer,
// } from "./index";

// const rootReducer = combineReducers({
//   case: caseReducer,
//   incident: incidentReducer,
//   narrative: narrativeReducer,
//   suspect: suspectReducer,
//   witnesses: witnessesReducer,
// });

// export default rootReducer;

import { AnyAction, combineReducers, Reducer } from "@reduxjs/toolkit";
import {
  caseReducer,
  incidentReducer,
  narrativeReducer,
  suspectReducer,
  witnessesReducer,
} from "./index";

const appReducer = combineReducers({
  case: caseReducer,
  incident: incidentReducer,
  narrative: narrativeReducer,
  suspect: suspectReducer,
  witnesses: witnessesReducer,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "case/clearResults") {
    state = {} as RootState;
  }
  return appReducer(state, action);
};

export default rootReducer;
export type RootState = ReturnType<typeof appReducer>;
