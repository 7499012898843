import { Dialog } from "@headlessui/react";
import { Officer } from "@ms1da/server";
import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RotatingLines } from "react-loader-spinner";
import { axiosClient } from "../../../axios";
import { useAppSelector } from "../../../redux/hook";
import "./styles.css";

function CreateOfficerForm() {
  let [isOpen, setIsOpen] = useState(true);
  const { register, handleSubmit, reset } = useForm<Officer>();
  const [errorMessage, setErrorMessage] = useState("");
  const caseId = useAppSelector((state) => state.case?.case?.id);
  const [isLoading, setIsLoading] = useState(false);
  const officer = useAppSelector((state) => state.case.case.officer);
  const caseData = useAppSelector((state) => state.case);

  useEffect(() => {
    // getCountyListForDropDown();
    if (officer.personSigningWarrant.length) {
      reset({
        personSigningWarrant: officer.personSigningWarrant,
        officerInCharge: officer.officerInCharge,
        email: officer.email,
        extension: officer.position,
        badgeNumber: officer.badgeNumber,
        phoneCell: officer.phoneCell,
        phoneWork: officer.phoneWork,
        phoneHome: officer.phoneHome,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitOfficer = handleSubmit(async (data) => {
    setErrorMessage("");
    setIsLoading(true);
    try {
      let submitOfficer = await axiosClient.put(
        `/cases/${caseId}/officer`,

        data,
        {
          headers: {
            Authorization: `Bearer ${window.sessionStorage.accessToken}`,
            "x-refresh-token": window.sessionStorage.refreshToken,
          },
        }
      );

      if (submitOfficer.status === 200) {
        // set case status and then head to /dash
        try {
          caseData.case.status = "submitted";

          const newCaseData = {
            ccNo: caseData.case?.ccNo || "",
            createdAt: caseData.case?.createdAt || "",
            crimeType: caseData.case?.crimeType || "",
            dcNo: caseData.case?.dcNo || "",
            defendant: caseData.case?.defendant || "",
            id: caseData.case?.id || "",
            incident: caseData.case?.incident || "",
            narrative: caseData.case.narrative || "",
            officer: caseData.case?.officer || "",
            officerCaseNumber: caseData.case?.officerCaseNumber || "",
            officerId: caseData.case?.officerId || "",
            status: caseData.case?.status || "",
            submittedBy: caseData.case?.submittedBy || "",
            updatedAt: caseData.case?.updatedAt || "",
            victim: caseData.case?.victim || "",
            witnesses: caseData.case?.witnesses || "",
            _recordId: caseData.case?._recordId || "",
          };

          const caseDataForSubmission = await axiosClient.put(
            `/cases/${caseId}`,
            {
              ...newCaseData,
            },
            {
              headers: {
                Authorization: `Bearer ${window.sessionStorage.accessToken}`,
                "x-refresh-token": window.sessionStorage.refreshToken,
              },
            }
          );

          if (caseDataForSubmission.status === 200) {
            setIsLoading(false);
            window.location.href = `/dashboard`;
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      if (error instanceof axios.AxiosError) {
        setIsLoading(false);
      }
    }
  });

  const back = () => {
    const userId = window.sessionStorage.userId;
    window.location.href = `/${userId}/documents`;
  };

  const prefillOfficerFields = async () => {
    const userData = await axiosClient.get(`/users/me`, {
      headers: {
        Authorization: `Bearer ${window.sessionStorage.accessToken}`,
        "x-refresh-token": window.sessionStorage.refreshToken,
      },
    });
    if (userData.status === 200) {
      reset({
        personSigningWarrant:
          userData.data.data.user.firstName +
          " " +
          userData.data.data.user.lastName,
        officerInCharge:
          userData.data.data.user.firstName +
          " " +
          userData.data.data.user.lastName,
        email: userData.data.data.user.email,
        extension: userData.data.data.user.position,
        badgeNumber: userData.data.data.user.badgeNumber,
        phoneCell: userData.data.data.user.phoneCell,
        phoneWork: userData.data.data.user.phoneWork,
        phoneHome: userData.data.data.user.phoneHome,
      });
      setIsOpen(false);
    }
  };

  return (
    <div className={isOpen ? "FormWrapper" : "FormWrapper"}>
      {officer.personSigningWarrant ? (
        ""
      ) : (
        <Dialog
          className="dialogOfficer"
          open={isOpen}
          onClose={() => setIsOpen(false)}
        >
          <Dialog.Panel>
            <Dialog.Title>Prefill Officer Form</Dialog.Title>
            <Dialog.Description>
              Are you the Officer in Charge?
            </Dialog.Description>

            <button
              className="officerCancelButton"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </button>
            <button
              className="officerPrefillButton"
              onClick={() => prefillOfficerFields()}
            >
              Prefill
            </button>
          </Dialog.Panel>
        </Dialog>
      )}

      <div className="fullWrapper">
        <div className="IncidentHeader">Officer</div>
        <form onSubmit={onSubmitOfficer}>
          <div className="CreateOfficerForm formRightBorder">
            <div className="combineStart">
              <div className="textFieldContainer marginRight">
                <label htmlFor="personSigningWarrant">
                  Person Signing Warrant
                </label>
                <input
                  type=""
                  placeholder="Person Signing Warrant"
                  className="individualFirstName textField"
                  id="personSigningWarrant"
                  {...register("personSigningWarrant")}
                />
              </div>

              <div className="textFieldContainer">
                <label htmlFor="officerInChargeOfCase">
                  Officer In Charge of Case
                </label>
                <input
                  type=""
                  placeholder="Officer In Charge of Case"
                  className="individualFirstName textField"
                  id="officerInChargeOfCase"
                  {...register("officerInCharge")}
                />
              </div>
            </div>

            <div className="combineStart">
              <div className="combineStart">
                <div className="textFieldContainer marginRight">
                  <label htmlFor="agency">Jurisdiction</label>
                  <input
                    type=""
                    placeholder="Jurisdiction"
                    className="individualFirstName textField"
                    id="agency"
                    {...register("jurisdiction")}
                  />
                </div>

                <div className="textFieldContainer">
                  <label htmlFor="department">Department</label>
                  <input
                    type=""
                    placeholder="Department"
                    className="individualFirstName textField"
                    id="department"
                    {...register("department")}
                  />
                </div>
              </div>
            </div>

            <div className="combineStart">
              <div className="textFieldContainer marginRight">
                <label htmlFor="badgeNumber">Badge Number</label>
                <input
                  type=""
                  placeholder="Badge Number"
                  className="individualFirstName textField"
                  id="badgeNumber"
                  {...register("badgeNumber")}
                />
              </div>

              <div className="textFieldContainer marginRight">
                <label htmlFor="position">Extension</label>
                <input
                  placeholder="Extension"
                  type=""
                  className="individualFirstName textField"
                  id="position"
                  {...register("extension")}
                />
              </div>

              <div className="textFieldContainer ">
                <label htmlFor="officerCaseNumber">Officer Case Number</label>
                <input
                  type=""
                  placeholder="Officer Case #"
                  className="individualFirstName textfieldSmall"
                  id="officerCaseNumber"
                  {...register("caseNumber")}
                />
              </div>
            </div>

            <div className="combineStart">
              <div className="textFieldContainer marginRight">
                <label htmlFor="phoneHome">Home Phone</label>
                <input
                  type=""
                  placeholder="Home Phone"
                  className="individualFirstName textField"
                  id="phoneHome"
                  {...register("phoneHome")}
                />
              </div>

              <div className="textFieldContainer marginRight">
                <label htmlFor="phoneCell">Cell Phone</label>
                <input
                  type=""
                  placeholder="Cell Phone"
                  className="individualFirstName textField"
                  id="phoneCell"
                  {...register("phoneCell")}
                />
              </div>

              <div className="textFieldContainer">
                <label htmlFor="phoneWork">Work Phone</label>
                <input
                  type=""
                  placeholder="Work Phone"
                  className="individualFirstName textfieldSmall"
                  id="phoneWork"
                  {...register("phoneWork")}
                />
              </div>
            </div>

            <div className="combineStart">
              <div className="textFieldContainer ">
                <label htmlFor="email">Email</label>
                <input
                  type=""
                  placeholder="Email"
                  className="individualFirstName textField"
                  id="email"
                  {...register("email")}
                />
              </div>
            </div>
          </div>

          <div className="navigateSectionsContainer">
            <button className="navigateSectionsButton" onClick={() => back()}>
              Back
            </button>
            {isLoading && (
              <div className="loginLoaderContainer">
                <RotatingLines
                  strokeColor="rgb(17,28,59)"
                  strokeWidth="5"
                  width="20"
                />
              </div>
            )}
            <button
              className="navigateSectionsButton"
              onClick={() => onSubmitOfficer()}
            >
              Submit Case
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateOfficerForm;
