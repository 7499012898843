import { z } from "zod";
import {
  confirmPasswordSchema,
  emailSchema,
  passwordSchema,
} from "./globals.schema";

const verificationLinkSchema = z.object({
  id: z.string(),
  userId: z.string(),
  token: z.string(),
  isPasswordResetLink: z.boolean().optional().default(false),
  _recordId: z.number(),
});

export const createVerificationLinkSchema = z.object({
  email: emailSchema,
});

export const forgotPasswordRequest = z.object({
  body: createVerificationLinkSchema,
});

export const resetPasswordSchema = z
  .object({
    token: z
      .string({ required_error: "token is required" })
      .min(1, "invalid token"),
    password: passwordSchema,
    confirmPassword: confirmPasswordSchema,
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "passwords do not match",
    path: ["confirmPassword"],
  });

export const resetPasswordRequest = z.object({
  body: resetPasswordSchema,
});

export type ForgotPasswordRequest = z.TypeOf<typeof forgotPasswordRequest>;
export type ResetPasswordRequest = z.TypeOf<typeof resetPasswordRequest>;
export type VerificationLink = z.TypeOf<typeof verificationLinkSchema>;
