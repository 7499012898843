import { z } from "zod";
import { BaseResponse } from "./response.schema";

const payload = {
  body: z.object({
    email: z
      .string({
        required_error: "email is required",
      })
      .email({
        message: "invalid email format",
      }),
    firstName: z.string({ required_error: "firstName is required" }),
    lastName: z.string({ required_error: "lastName is required" }),
    phoneHome: z.string().optional(),
    phoneWork: z.string().optional(),
    phoneCell: z.string().optional(),
    agency: z.string().optional(),
    position: z.string().optional(),
    badgeNumber: z.string().optional(),
  }),
};

export const createUserSchema = z.object({
  body: payload.body.extend({
    password: z.string({
      required_error: "password is required",
    }),
  }),
});

export const updateUserSchema = z.object({
  body: payload.body,
});

export const userSchema = payload.body.extend({
  id: z.string().optional(),
  createdAt: z.number().optional(),
  updatedAt: z.number().optional(),
  password: z.string(),
  badgeNumber: z.string(),
  canAccessPolicePortal: z.boolean().optional(),
  isActive: z.boolean().optional(),
  _recordId: z.number().optional(),
});

export type User = z.TypeOf<typeof userSchema>;
export type ProtectedUser = Omit<User, "password">;

export type CreateUserInput = CreateUserRequest["body"];
export type CreateUserRequest = z.TypeOf<typeof createUserSchema>;
export type CreateUserResponse = BaseResponse & {
  data: { user: ProtectedUser | null };
};

export type UpdateUserRequest = z.TypeOf<typeof updateUserSchema>;
export type UpdateUserInput = UpdateUserRequest["body"];
export type UpdateUserResponse = CreateUserResponse;

export type GetCurrentUserResponse = CreateUserResponse;
