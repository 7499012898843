import { Case } from "@ms1da/server";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
interface CaseWithCrimeType extends Case {
  crimeType: string;
}
interface CaseState {
  case: CaseWithCrimeType | null;
}

const initialState: CaseState = { case: null };

export const CaseSlice = createSlice({
  name: "case",
  initialState,
  reducers: {
    setCase: (state, action: PayloadAction<CaseWithCrimeType>) => {
      state.case = action.payload;
    },
    setIncident: (state, action: PayloadAction<Case["incident"]>) => {
      if (state.case) {
        state.case = { ...state.case, incident: action.payload };
      }
    },
    setSuspect: (state, action: PayloadAction<Case["defendant"]>) => {
      if (state.case) {
        state.case = { ...state.case, defendant: action.payload };
      }
    },
    setVictim: (state, action: PayloadAction<Case["victim"]>) => {
      if (state.case) {
        state.case = { ...state.case, victim: action.payload };
      }
    },
    setOfficer: (state, action: PayloadAction<Case["officer"]>) => {
      if (state.case) {
        state.case = { ...state.case, officer: action.payload };
      }
    },
    clearResults() {},
  },
});

export const { setCase, setIncident, setSuspect, setVictim, clearResults } =
  CaseSlice.actions;

export const getCase = (state: RootState) => state.case;

export const getIncident = (state: RootState) => state.case?.case?.incident;

export const getSuspect = (state: RootState) => state.case?.case?.defendant;

export const getVictim = (state: RootState) => state.case?.case?.victim;

export default CaseSlice.reducer;
