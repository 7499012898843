import { Case } from "@ms1da/server";
import { useAppDispatch } from "../../../../../redux/hook";
import { setCase } from "../../../../../redux/slices/CaseSlice";
import "./styles.css";

function Nav() {
  const userId = window.sessionStorage.userId;
  const dispatch = useAppDispatch();

  const clearStoreAndRedirect = () => {
    dispatch(
      setCase({
        // @ts-ignore
        ...(null as Case),
        crimeType: null || "",
      })
    );
    window.location.href = "/dashboard";
  };

  return (
    <div className="Nav">
      <div className="navItemContainer">
        <div className="navItemindividualContainer">
          <div className="stepperIcon">1</div>

          <div
            className={
              window.location.href ===
              `http://localhost:3000/${userId}/incident`
                ? "activeNavButton navItem"
                : "navItem"
            }
            onClick={() => (window.location.href = `/${userId}/incident`)}
          >
            Incident
          </div>
        </div>

        <div className="navItemindividualContainer">
          <div className="stepperIcon">2</div>

          <div
            className={
              window.location.href ===
              `http://localhost:3000/${userId}/narrative`
                ? "activeNavButton navItem"
                : "navItem"
            }
            onClick={() => (window.location.href = `/${userId}/narrative`)}
          >
            Narrative
          </div>
        </div>

        <div className="navItemindividualContainer">
          <div className="stepperIcon">3</div>

          <div
            className={
              window.location.href === `http://localhost:3000/${userId}/suspect`
                ? "activeNavButton navItem"
                : "navItem"
            }
            onClick={() => (window.location.href = `/${userId}/suspect`)}
          >
            Suspect
          </div>
        </div>

        <div className="navItemindividualContainer">
          <div className="stepperIcon">4</div>

          <div
            className={
              window.location.href === `http://localhost:3000/${userId}/victims`
                ? "activeNavButton navItem"
                : "navItem"
            }
            onClick={() => (window.location.href = `/${userId}/victims`)}
          >
            Victim
          </div>
        </div>

        <div className="navItemindividualContainer">
          <div className="stepperIcon">5</div>

          <div
            className={
              window.location.href ===
              `http://localhost:3000/${userId}/witnesses`
                ? "activeNavButton navItem"
                : "navItem"
            }
            onClick={() => (window.location.href = `/${userId}/witnesses`)}
          >
            Witnesses
          </div>
        </div>

        <div className="navItemindividualContainer">
          <div className="stepperIcon">6</div>

          <div
            className={
              window.location.href ===
              `http://localhost:3000/${userId}/documents`
                ? "activeNavButton navItem"
                : "navItem"
            }
            onClick={() => (window.location.href = `/${userId}/documents`)}
          >
            Documents
          </div>
        </div>

        <div className="navItemindividualContainer">
          <div className="stepperIcon">7</div>

          <div
            className={
              window.location.href === `http://localhost:3000/${userId}/officer`
                ? "activeNavButton navItem"
                : "navItem"
            }
            onClick={() => (window.location.href = `/${userId}/officer`)}
          >
            Officer
          </div>
        </div>
      </div>
      <div
        className="dashboardLinkButton"
        // onClick={() => (window.location.href = "/dashboard")}
        onClick={() => clearStoreAndRedirect()}
      >
        Dashboard
      </div>
    </div>
  );
}

export default Nav;
