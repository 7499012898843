import { User } from "@ms1da/server";
import axios from "axios";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { RotatingLines } from "react-loader-spinner";
import { axiosClient } from "../../../axios";
import { useAppSelector } from "../../../redux/hook";
import "./styles.css";

function RequestAccess() {
  const { register, handleSubmit, formState } = useForm<User>();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const caseId = useAppSelector((state) => state.case?.case?.id);
  let confirmPassword = useRef("");
  const { errors } = formState;

  const onSubmitRequest = handleSubmit(async (data) => {
    setIsLoading(true);
    setErrorMessage("");
    // @ts-ignore
    if (confirmPassword.current.value !== data.password) {
      alert("Passwords must match");
      setIsLoading(false);
      return;
    } else if (data.firstName === "" || data.lastName === "") {
      alert("First and Last names are required.");
      setIsLoading(false);
      return;
    } else {
      try {
        let createRequest = await axiosClient.post(`/users/`, data);
        if (createRequest.status === 201) {
          setIsLoading(false);
          setSuccessMessage("Successfully requested access");
        }
      } catch (error) {
        if (error instanceof axios.AxiosError) {
          setIsLoading(false);
          if (error.response?.data.message === "invalid email format") {
            setErrorMessage("Invalid email format");
          } else if (
            error.response?.data.message === "Account already exists"
          ) {
            setErrorMessage("Account already exists");
          } else {
            setErrorMessage("Issue requesting access, please try again");
          }
        }
      }
    }
  });

  const back = () => {
    const userId = window.sessionStorage.userId;
    window.location.href = `/`;
  };

  return (
    <div className="FormWrapper">
      <div className="fullWrapper">
        <div className="IncidentHeader">Request Access</div>
        <form onSubmit={onSubmitRequest}>
          <div className="CreateOfficerForm">
            <div className="combineStart">
              <div className="textFieldContainer marginRight">
                <label htmlFor="firstName">First Name</label>
                <input
                  type=""
                  placeholder="First Name"
                  className="individualFirstName textField"
                  id="firstName"
                  {...register("firstName")}
                />
              </div>

              <div className="invalid-feedback">
                {errors.firstName?.message}
              </div>

              <div className="textFieldContainer marginRight">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type=""
                  placeholder="Last Name"
                  className="individualFirstName textField"
                  id="lastName"
                  {...register("lastName")}
                />
                <div className="invalid-feedback">
                  {errors.lastName?.message}
                </div>
              </div>

              <div className="textFieldContainer marginRight">
                <label htmlFor="email">Email</label>
                <input
                  type=""
                  placeholder="Email"
                  className="individualFirstName textField"
                  id="email"
                  {...register("email")}
                />

                <div className="invalid-feedback">{errors.email?.message}</div>
              </div>
            </div>

            <div className="combineStart">
              <div className="textFieldContainer marginRight">
                <label htmlFor="agency">Agency</label>
                <input
                  type=""
                  placeholder="Agency"
                  className="individualFirstName textField"
                  id="agency"
                  {...register("agency")}
                />
                <div className="invalid-feedback">{errors.agency?.message}</div>
              </div>

              <div className="textFieldContainer marginRight">
                <label htmlFor="badgeNumber">Badge Number</label>
                <input
                  type=""
                  placeholder="Badge Number"
                  className="individualFirstName textField"
                  id="badgeNumber"
                  {...register("badgeNumber")}
                />
                <div className="invalid-feedback">
                  {errors.badgeNumber?.message}
                </div>
              </div>

              <div className="textFieldContainer marginRight">
                <label htmlFor="position">Position</label>
                <input
                  type=""
                  placeholder="Position"
                  className="individualFirstName textField"
                  id="position"
                  {...register("position")}
                />
                <div className="invalid-feedback">
                  {errors.position?.message}
                </div>
              </div>
            </div>
          </div>

          <div className="combineStart">
            {/* <div className="textFieldContainer marginRightLong">
              <label htmlFor="phoneHome">Home Phone</label>
              <input
                placeholder="Phone Number"
                type=""
                className="individualFirstName textfieldSmall"
                id="phoneHome"
                {...register("phoneHome")}
              />
              <div className="invalid-feedback">
                {errors.phoneHome?.message}
              </div>
            </div> */}

            <div className="textFieldContainer marginRightLong">
              <label htmlFor="phoneWork">Work Phone</label>
              <input
                placeholder="Phone Number"
                type=""
                className="individualFirstName textfieldSmall"
                id="phoneWork"
                {...register("phoneWork")}
              />
              <div className="invalid-feedback">
                {errors.phoneWork?.message}
              </div>
            </div>

            <div className="textFieldContainer">
              <label htmlFor="phoneCell">Cell Phone</label>
              <input
                placeholder="Phone Number"
                type=""
                className="individualFirstName textfieldSmall"
                id="phoneCell"
                {...register("phoneCell")}
              />
              <div className="invalid-feedback">
                {errors.phoneCell?.message}
              </div>
            </div>
          </div>
          <div className="combineStart">
            <div className="textFieldContainer marginRightLong">
              <label htmlFor="password">Password</label>
              <input
                placeholder="Password"
                type="password"
                className="individualFirstName textfieldSmall"
                id="password"
                {...register("password")}
              />
              <div className="invalid-feedback">{errors.password?.message}</div>
            </div>
            <div className="textFieldContainer">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input
                placeholder="Confirm Password"
                type="password"
                className="individualFirstName textfieldSmall"
                id="confirmPassword"
                // @ts-ignore
                ref={confirmPassword}
              />
            </div>
          </div>

          <div className="requestAccessMessageContainer">
            {isLoading && (
              <div className="loginLoaderContainer">
                <RotatingLines
                  strokeColor="rgb(17,28,59)"
                  strokeWidth="5"
                  width="20"
                />
              </div>
            )}
            {errorMessage ? (
              <div className="invalid-feedback">{errorMessage}</div>
            ) : (
              ""
            )}
            {successMessage ? (
              <div className="valid-feedback">{successMessage}</div>
            ) : (
              ""
            )}
          </div>

          <div className="navigateSectionsContainer">
            <button
              className="navigateSectionsButton"
              type="button"
              onClick={() => (window.location.href = "/")}
            >
              Back
            </button>
            <button className="navigateSectionsButton" type="submit">
              Submit Request
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default RequestAccess;
