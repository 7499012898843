import { z } from "zod";
import { defendantSchema } from "./defendant.schema";
import { incidentInput } from "./incident.schema";
import { officerSchema } from "./officer.schema";
import { BaseResponse, CaseResponse, CasesResponse } from "./response.schema";
import { uploadSchema } from "./upload.schema";
import { victimSchema } from "./victim.schema";
import { witnessSchema } from "./witness.schema";

// What Case properties can be modified
const payload = {
  body: z.object({
    officerId: z.string(),
    officerCaseNumber: z.string(),
    submittedBy: z.string(),
    status: z.string(),
    ccNo: z.string(),
    dcNo: z.string(),
    narrative: z.string().optional(),
  }),
};

// What a Case looks like in the db
const caseSchema = payload.body.extend({
  _recordId: z.number(),
  id: z.string().uuid(),
  createdAt: z.number(),
  updatedAt: z.number(),
  rejectedReason: z.string().optional(),
  officer: officerSchema,
  incident: incidentInput,
  defendant: defendantSchema,
  victim: victimSchema,
  witnesses: z.array(witnessSchema),
});

const caseWithDocumentsSchema = caseSchema.extend({
  documents: z.array(uploadSchema),
});

const params = {
  params: z.object({
    caseId: z.string({ required_error: "caseId is required" }),
  }),
};

const query = {
  query: z.object({
    officerId: z.string().optional(),
    status: z.string().optional(),
  }),
};

export const createCaseSchema = z.object({
  ...payload,
});

export const getCaseByIdSchema = z.object({
  ...params,
});

export const getManyCasesSchema = z.object({
  ...query,
});

export const updateCaseSchema = z.object({
  ...params,
  ...payload,
});

export const deleteCaseSchema = z.object({
  ...params,
});

export type Case = z.TypeOf<typeof caseSchema>;
export type CaseWithDocuments = z.TypeOf<typeof caseWithDocumentsSchema>;

// Create Case
export type CreateCaseInput = CreateCaseRequest["body"];
export type CreateCaseRequest = z.TypeOf<typeof createCaseSchema>;
export type CreateCaseResponse = CaseResponse;

// Update Case
export type UpdateCaseInput = UpdateCaseRequest["body"];
export type UpdateCaseRequest = z.TypeOf<typeof updateCaseSchema>;
export type UpdateCaseResponse = CaseResponse;

// Find many Cases
export type GetCasesRequest = z.TypeOf<typeof getManyCasesSchema>;
export type GetCasesResponse = CasesResponse;

// Find Case
export type GetCaseByIdRequest = z.TypeOf<typeof getCaseByIdSchema>;
export type GetCaseByIdResponse = CaseResponse & {
  data: { case: CaseWithDocuments | null };
};

// Delete Case
export type DeleteCaseRequest = z.TypeOf<typeof deleteCaseSchema>;
export type DeleteCaseResponse = BaseResponse;
