import { Case } from "@ms1da/server";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RotatingLines } from "react-loader-spinner";
import { Narrative } from "../../../Types";
import { axiosClient } from "../../../axios";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { setCase } from "../../../redux/slices/CaseSlice";
import { setNarrative } from "../../../redux/slices/NarrativeSlice";
import "./styles.css";

function CreateNarrativeForm() {
  const { register, handleSubmit, reset } = useForm<Narrative>();
  const [isLoading, setIsLoading] = useState(false);
  const setNarrativeData = (narrativeData: any) => {
    if (narrativeData !== null) {
      reset({
        narrative: narrativeData,
      });
    }
  };

  const narrativeData = useAppSelector((state) => state.case.case);
  const caseData = useAppSelector((state) => state.case);
  const caseId = useAppSelector((state) => state.case?.case?.id);

  let newCaseData: any;
  const dispatch = useAppDispatch();

  const submit = handleSubmit(async (data) => {
    setIsLoading(true);
    if (data.narrative.length > 0) {
      let caseDataWithNarrative = {
        ccNo: caseData.case?.ccNo,
        createdAt: caseData.case?.createdAt,
        crimeType: caseData.case?.crimeType,
        dcNo: caseData.case?.dcNo,
        defendant: caseData.case?.defendant,
        id: caseData.case?.id,
        incident: caseData.case?.incident,
        narrative: data.narrative,
        officer: caseData.case?.officer,
        officerCaseNumber: caseData.case?.officerCaseNumber,
        officerId: caseData.case?.officerId,
        status: caseData.case?.status,
        submittedBy: caseData.case?.submittedBy,
        updatedAt: caseData.case?.updatedAt,
        victim: caseData.case?.victim,
        witnesses: caseData.case?.witnesses,
        _recordId: caseData.case?._recordId,
      };

      newCaseData = caseDataWithNarrative;

      dispatch(
        setCase({
          ...(caseDataWithNarrative as Case),
          crimeType: caseData.case?.crimeType || "",
        })
      );
    } else {
      const userId = window.sessionStorage.userId;
      window.location.href = `/${userId}/suspect`;
      return;
    }

    dispatch(setNarrative(data));
    try {
      const createNarrative = await axiosClient.put(
        `/cases/${caseId}`,
        newCaseData,
        {
          headers: {
            Authorization: `Bearer ${window.sessionStorage.accessToken}`,
            "x-refresh-token": window.sessionStorage.refreshToken,
          },
        }
      );

      if (createNarrative.status === 200) {
        const userId = window.sessionStorage.userId;
        setIsLoading(false);
        window.location.href = `/${userId}/suspect`;
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  });

  const back = () => {
    const userId = window.sessionStorage.userId;
    window.location.href = `/${userId}/incident`;
  };

  useEffect(() => {
    if (narrativeData !== null) {
      setNarrativeData(narrativeData.narrative);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="FormWrapper">
      <div className="fullWrapper">
        <div className="narrativeHeader">Narrative</div>
        <div className="textFieldContainer">
          <label htmlFor="narrative">Case Narrative</label>
          <textarea
            placeholder="Narrative"
            className="individualFirstName narrativeTextArea"
            id="narrative"
            {...register("narrative")}
          />
        </div>
        <div className="navigateSectionsContainer">
          <button
            className="navigateSectionsButton"
            type="button"
            onClick={() => back()}
          >
            Back
          </button>
          {isLoading && (
            <div className="loginLoaderContainer">
              <RotatingLines
                strokeColor="rgb(17,28,59)"
                strokeWidth="5"
                width="20"
              />
            </div>
          )}
          <button
            type="submit"
            className="navigateSectionsButton"
            onClick={() => submit()}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateNarrativeForm;
