import { Case, Incident } from "@ms1da/server";
import { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { RotatingLines } from "react-loader-spinner";
import { axiosClient } from "../../../../axios";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { setCase, setIncident } from "../../../../redux/slices/CaseSlice";
import DrugIncidentOptionsForm from "../DrugIncidentOptionsForm/DrugIncidentOptionsForm";
import OtherIncidentOptionsForm from "../OtherIncidentOptionsForm/OtherIncidentOptionsForm";
import PersonIncidentOptionsForm from "../PersonIncidentOptionsForm/PersonIncidentOptionsform";
import PropertyIncidentOptionsForm from "../PropertyIncidentOptionsForm/PropertyIncidentOptionsForm";
import SexIncidentOptionsForm from "../SexIncidentOptionsForm/SexIncidentOptionsForm";
import "./styles.css";

function CreateIncidentForm() {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const caseData = useAppSelector((state) => state.case?.case);

  const [selectedCrimeType, setSelectedCrimeType] = useState<
    string | undefined
  >("");

  const crimeTypeSelection = useRef<HTMLSelectElement>(null);

  const checkForCrimeTypeSelection = () => {
    if (crimeTypeSelection === null) {
    } else {
      dispatch(
        setCase({
          ...(caseData as Case),
          crimeType: crimeTypeSelection.current?.value || "",
        })
      );
      setSelectedCrimeType(crimeTypeSelection.current?.value);
      return crimeTypeSelection.current?.value;
    }
  };

  const methods = useForm<Incident>();
  const caseId = useAppSelector((state) => state.case?.case?.id);

  const onSubmitIncident = methods.handleSubmit(async (data) => {
    setIsLoading(true);
    if (data.charge === "") {
      alert("Charge is required");
      setIsLoading(false);
      return;
    }
    if (data.date === "undefined--undefined") {
      alert("Date is required");
      setIsLoading(false);
      return;
    }

    if (data.county === "") {
      alert("County is required");
      setIsLoading(false);
      return;
    }
    // let newData = parseDataForCorrectCrimeTypeValue(data);
    if (data.date) {
      let date = data.date.split("-");
      const structuredDate = `${date[1]}/${date[2]}/${date[0]}`;
      data.date = structuredDate;
    }

    if (crimeTypeSelection.current?.value) {
      data = {
        ...data,
        crimeType: crimeTypeSelection.current?.value,
      };
      dispatch(setIncident(data));
    } else {
      dispatch(setIncident(data));
    }

    try {
      const createIncident = await axiosClient.put(
        `/cases/${caseId}/incident`,
        data,
        {
          headers: {
            Authorization: `Bearer ${window.sessionStorage.accessToken}`,
            "x-refresh-token": window.sessionStorage.refreshToken,
          },
        }
      );
      if (createIncident.status === 200) {
        setIsLoading(false);
        const userId = window.sessionStorage.userId;
        window.location.href = `/${userId}/narrative`;
      }
    } catch (error) {
      setIsLoading(false);
    }
  });

  const setIncidentData = (incidentData: Incident) => {
    let formattedDate = "";
    if (incidentData.date?.split("/")) {
      const splitDate = incidentData.date?.split("/");
      formattedDate = `${splitDate[2]}-${splitDate[0]}-${splitDate[1]}`;
    }

    methods.reset({
      street: incidentData.street,
      aptNo: incidentData.aptNo,
      city: incidentData.city,
      county: incidentData.county,
      state: incidentData.state,
      zip: incidentData.zip,
      charge: incidentData.charge,
      date: formattedDate || "",
      time: incidentData.time,
      crimeType: incidentData.crimeType,
      crimeDetails: incidentData.crimeDetails,
      isSexCase: incidentData.isSexCase,
      isDomesticViolenceCase: incidentData.isDomesticViolenceCase,
      arrestTrackingNumber: incidentData.arrestTrackingNumber,
      isDrugsFirearmCase: incidentData.isDrugsFirearmCase,
      isSchoolDrugsCase: incidentData.isSchoolDrugsCase,
      schoolName: incidentData.schoolName,
      propertyTaken: incidentData.propertyTaken,
      propertyValue: incidentData.propertyValue,
      nameOfDrugs: incidentData.nameOfDrugs,
      traffickedAmount: incidentData.traffickedAmount,
      toxNumber: incidentData.toxNumber,
      chainOfCustody: incidentData.chainOfCustody,
      sexCrimeType: incidentData.sexCrimeType,
      isRapeKitIncluded: incidentData.isRapeKitIncluded,
    });

    if (incidentData.crimeType) {
      setSelectedCrimeType(incidentData.crimeType);
      crimeTypeSelection.current!.value = incidentData.crimeType;
    }
  };

  const incidentData = useAppSelector((state) => state.case?.case?.incident);
  const dataGen = useAppSelector((state) => state.case?.case);

  useEffect(() => {
    // getCountyListForDropDown();
    if (incidentData && incidentData) {
      setIncidentData(incidentData);
      checkForCrimeTypeSelection();
    }
  }, []);

  return (
    <FormProvider {...methods}>
      <div className="FormWrapper">
        <div className="fullWrapper">
          <form onSubmit={onSubmitIncident}>
            <div className="IncidentHeader">Incident</div>
            <div className="CreateIncidentForm">
              <div className="combine">
                <div className="textFieldContainerLong address">
                  <label htmlFor="address">Incident Address</label>
                  <input
                    className="individualFirstName textFieldLongIncident "
                    placeholder="Incident Address"
                    id="address"
                    {...methods.register("street")}
                  />
                </div>

                <div className="textFieldContainer aptNo">
                  <label htmlFor="apartmentNumber">Apt No.</label>
                  <input
                    className="individualFirstName textfieldSmall"
                    placeholder="Apt No."
                    id="apartmentNumber"
                    {...methods.register("aptNo")}
                  />
                </div>
              </div>

              <div className="combine">
                <div className="textFieldContainer city">
                  <label htmlFor="city">City</label>
                  <input
                    className="individualFirstName textfieldSmall"
                    placeholder="City"
                    id="city"
                    {...methods.register("city")}
                  />
                </div>

                <div className="textFieldContainer state">
                  <label htmlFor="state">State</label>
                  <select
                    className="individualFirstName selectFieldSmall standardText"
                    id="state"
                    {...methods.register("state")}
                  >
                    {" "}
                    <option className="greyTextDefault" value="" selected>
                      Select State
                    </option>
                    <option value="AK">AK</option>
                    <option value="AL">AL</option>
                    <option value="AR">AR</option>
                    <option value="AS">AS</option>
                    <option value="AZ">AZ</option>
                    <option value="CA">CA</option>
                    <option value="CO">CO</option>
                    <option value="CT">CT</option>
                    <option value="DC">DC</option>
                    <option value="DE">DE</option>
                    <option value="FL">FL</option>
                    <option value="GA">GA</option>
                    <option value="GU">GU</option>
                    <option value="HI">HI</option>
                    <option value="IA">IA</option>
                    <option value="ID">ID</option>
                    <option value="IL">IL</option>
                    <option value="IN">IN</option>
                    <option value="KS">KS</option>
                    <option value="KY">KY</option>
                    <option value="LA">LA</option>
                    <option value="MA">MA</option>
                    <option value="MD">MD</option>
                    <option value="ME">ME</option>
                    <option value="MI">MI</option>
                    <option value="MN">MN</option>
                    <option value="MO">MO</option>
                    <option value="MS">MS</option>
                    <option value="MT">MT</option>
                    <option value="NC">NC</option>
                    <option value="ND">ND</option>
                    <option value="NE">NE</option>
                    <option value="NH">NH</option>
                    <option value="NJ">NJ</option>
                    <option value="NM">NM</option>
                    <option value="NV">NV</option>
                    <option value="NY">NY</option>
                    <option value="OH">OH</option>
                    <option value="OK">OK</option>
                    <option value="OR">OR</option>
                    <option value="PA">PA</option>
                    <option value="PR">PR</option>
                    <option value="RI">RI</option>
                    <option value="SC">SC</option>
                    <option value="SD">SD</option>
                    <option value="TN">TN</option>
                    <option value="TX">TX</option>
                    <option value="UT">UT</option>
                    <option value="VA">VA</option>
                    <option value="VI">VI</option>
                    <option value="VT">VT</option>
                    <option value="WA">WA</option>
                    <option value="WI">WI</option>
                    <option value="WV">WV</option>
                    <option value="WY">WY</option>
                  </select>
                </div>

                <div className="textFieldContainer zip">
                  <label htmlFor="zip">Zip</label>
                  <input
                    className="individualFirstName textfieldSmall"
                    placeholder="Zip"
                    id="zip"
                    {...methods.register("zip")}
                  />
                </div>
              </div>

              <div className="combine">
                <div className="textFieldContainer charge">
                  <label htmlFor="county">
                    County<span className="required">*</span>
                  </label>
                  <select
                    className="individualFirstName selectFieldSmall standardText"
                    id="county"
                    {...methods.register("county")}
                  >
                    {" "}
                    <option value="" disabled selected>
                      Select County
                    </option>
                    <option value="Alcorn">Alcorn</option>
                    <option value="Itawamba">Itawamba</option>
                    <option value="Lee">Lee</option>
                    <option value="Monroe">Monroe</option>
                    <option value="Pontotoc">Pontotoc</option>
                    <option value="Prentiss">Prentiss</option>
                    <option value="Tishomingo">Tishomingo</option>
                  </select>
                </div>

                <div className="textFieldContainer incidentDate">
                  <label htmlFor="date">
                    Date<span className="required">*</span>
                  </label>
                  <input
                    type="date"
                    className="individualFirstName textfieldSmall standardText"
                    id="date"
                    {...methods.register("date")}
                  />
                </div>

                <div className="textFieldContainer incidentTime">
                  <label htmlFor="time">Time</label>
                  <input
                    type="time"
                    className="individualFirstName textfieldSmall standardText"
                    id="time"
                    {...methods.register("time")}
                  />
                </div>
              </div>

              <div className="combineStart">
                <div className="textFieldContainer charg marginRight">
                  <label htmlFor="charge">
                    Charge<span className="required">*</span>
                  </label>
                  <input
                    className="individualFirstName textField"
                    placeholder="Charge"
                    id="charge"
                    {...methods.register("charge")}
                  />
                </div>

                <div className="textFieldContainer  ATN">
                  <label htmlFor="arrestTrackingNumber">
                    Arrest Tracking Number
                  </label>
                  <input
                    className="individualFirstName textfieldSmall "
                    placeholder="000"
                    id="arrestTrackingNumber"
                    {...methods.register("arrestTrackingNumber")}
                  />
                </div>
              </div>
            </div>

            <div className="incidentCrimeTypeWrapper">
              <div className="textFieldContainer">
                <label htmlFor="crimeType">Crime Type</label>
                <select
                  className="individualFirstName textField standardText"
                  id="crimeType"
                  ref={crimeTypeSelection}
                  // {...methods.register("crimeType")}
                  onChange={() => checkForCrimeTypeSelection()}
                >
                  <option value="" disabled selected>
                    Select Incident
                  </option>
                  <option value="Drug Incident">Drug Incident</option>
                  <option value="Person Incident">Person Incident</option>
                  <option value="Property Incident">Property Incident</option>
                  <option value="Sex Incident">Sex Incident</option>
                  <option value="Other Incident">Other Incident</option>
                </select>
              </div>
            </div>

            {selectedCrimeType === "Person Incident" ? (
              <PersonIncidentOptionsForm />
            ) : (
              ""
            )}
            {selectedCrimeType === "Drug Incident" ? (
              <DrugIncidentOptionsForm />
            ) : (
              ""
            )}
            {selectedCrimeType === "Property Incident" ? (
              <PropertyIncidentOptionsForm />
            ) : (
              ""
            )}
            {selectedCrimeType === "Sex Incident" ? (
              <SexIncidentOptionsForm />
            ) : (
              ""
            )}
            {selectedCrimeType === "Other Incident" ? (
              <OtherIncidentOptionsForm />
            ) : (
              ""
            )}
          </form>

          <div className="navigateSectionsContainer">
            <button className="navigateSectionsButton">Cancel</button>
            {isLoading && (
              <div className="loginLoaderContainer">
                <RotatingLines
                  strokeColor="rgb(17,28,59)"
                  strokeWidth="5"
                  width="20"
                />
              </div>
            )}
            <button
              className="navigateSectionsButton"
              onClick={() => onSubmitIncident()}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </FormProvider>
  );
}

export default CreateIncidentForm;
