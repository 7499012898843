import { z } from "zod";
import { BaseResponse, WitnessResponse } from "./response.schema";

export const witnessInputSchema = z.object({
  firstName: z
    .string({ required_error: "firstName is required" })
    .min(1, "first name is required"),
  middleName: z.string().optional(),
  lastName: z
    .string({ required_error: "lastName is required" })
    .min(1, "last name is required"),
  address: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  zip: z.string().optional(),
  race: z.string().optional(),
  sex: z.string().optional(),
  dateOfBirth: z.string().optional(),
  ssn: z.string().optional(),
  email: z.string().optional(),
  driversLicenseNumber: z.string().optional(),
  driversLicenseState: z.string().optional(),
  role: z.string().optional(),
});

export const witnessSchema = witnessInputSchema.extend({
  id: z.string().uuid(),
  caseId: z.string(),
  _recordId: z.number(),
});

const baseParams = {
  params: z.object({
    caseId: z.string({ required_error: "caseId is required" }),
  }),
};

const params = {
  params: baseParams.params.extend({
    witnessId: z.string({ required_error: "witnessId is required" }),
  }),
};

const payload = {
  body: witnessInputSchema,
};

export const createWitnessRequest = z.object({
  ...baseParams,
  ...payload,
});

export const getWitnessRequest = z.object({
  ...params,
});

export const updateWitnessRequest = z.object({
  ...params,
  ...payload,
});

export const deleteWitnessRequest = z.object({
  ...params,
});

export type WitnessInput = z.TypeOf<typeof witnessInputSchema>;
export type Witness = z.TypeOf<typeof witnessSchema>;

// Request types
export type CreateWitnessInput = CreateWitnessRequest["body"];
export type CreateWitnessRequest = z.TypeOf<typeof createWitnessRequest>;
export type CreateWitnessResponse = WitnessResponse;

export type GetWitnessRequest = z.TypeOf<typeof getWitnessRequest>;
export type GetWitnessResponse = WitnessResponse;

export type EditWitnessInput = EditWitnessRequest["body"];
export type EditWitnessRequest = z.TypeOf<typeof updateWitnessRequest>;
export type EditWitnessResponse = WitnessResponse;

export type DeleteWitnessRequest = z.TypeOf<typeof deleteWitnessRequest>;
export type DeleteWitnessResponse = BaseResponse;
