import { Menu } from "@headlessui/react";
import { Fragment } from "react";
import { axiosClient } from "../../../../../axios";
import "./styles.css";

function Header() {
  const logout = async () => {
    await axiosClient.delete("/sessions", {
      headers: {
        Authorization: `Bearer ${window.sessionStorage.accessToken}`,
        "x-refresh-token": window.sessionStorage.refreshToken,
      },
    });
    window.sessionStorage.clear();
    window.location.href = "/";
  };

  const logStuff = () => {
    const userId = window.sessionStorage.getItem("userId");
    window.location.href = `/${userId}/updateUser`;
  };

  const links = [
    { label: "User", function: logStuff },
    { label: "Logout", function: logout },
  ];

  return (
    <div className="Header">
      <div className="headerText">
        Mississippi's 1st District Attorneys Office - Police Portal
      </div>
      <div className="headerRightContainer">
        {window.sessionStorage.userFirstName ? (
          <div className="userGreetingMarginRight">
            Welcome, {window.sessionStorage.userFirstName}{" "}
          </div>
        ) : (
          ""
        )}

        {window.sessionStorage.userFirstName ? (
          <Menu>
            <Menu.Button className="menuButtonHeader">
              <i className="fa-solid fa-chevron-down"></i>
            </Menu.Button>
            {/* <div className="menuOptionsContainer"> */}
            <Menu.Items className="menuOptionsContainer">
              {links.map((link) => (
                /* Use the `active` state to conditionally style the active item. */
                <Menu.Item key={link.label} as={Fragment}>
                  {({ active }) => (
                    <a
                      // href={link.href}
                      onClick={link.function}
                      className={`${
                        active
                          ? "bg-blue-500 text-white "
                          : "bg-white text-black"
                      } menuItem pointer`}
                    >
                      {link.label}
                    </a>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
            {/* </div> */}
          </Menu>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Header;
