// import { useEffect } from "react";
import { UpdateVictimInput } from "@ms1da/server";

import { useFormContext } from "react-hook-form";

function BusinessVictimOptionsForm() {
  const { register, reset } = useFormContext<UpdateVictimInput>();

  return (
    <div className="BusinessVictimOptionsForm formRightBorder">
      <div className="combineStart">
        <div className="textFieldContainer marginRight">
          <label htmlFor="businessName">Business Name</label>
          <input
            className="individualFirstName textField"
            placeholder="Business Name"
            id="businessName"
            {...register("corporationNameAndRepresentative")}
          />
        </div>

        <div className="textFieldContainer">
          <label htmlFor="contactPhone">Contact Phone</label>
          <input
            placeholder="Contact Phone"
            className="individualFirstName textfieldSmall"
            id="contactPhone"
            {...register("phoneCell")}
          />
        </div>
      </div>

      <div className="textFieldContainer">
        <label htmlFor="street">Address</label>

        <input
          className="individualFirstName textFieldLong"
          placeholder="Address"
          id="street"
          {...register("address")}
        />
      </div>

      <div className="combineStart">
        <div className="textFieldContainer marginRight">
          <label htmlFor="city">City</label>
          <input
            className="individualFirstName textfieldSmall"
            placeholder="City"
            id="city"
            {...register("city")}
          />
        </div>

        <div className="textFieldContainer marginRight">
          <label htmlFor="state">State</label>
          <select
            className="individualFirstName textfieldSmall"
            id="state"
            {...register("state")}
            // options={stateOptions}
          >
            {" "}
            <option value="" disabled selected>
              State
            </option>
            <option value="AK">AK</option>
            <option value="AL">AL</option>
            <option value="AR">AR</option>
            <option value="AS">AS</option>
            <option value="AZ">AZ</option>
            <option value="CA">CA</option>
            <option value="CO">CO</option>
            <option value="CT">CT</option>
            <option value="DC">DC</option>
            <option value="DE">DE</option>
            <option value="FL">FL</option>
            <option value="GA">GA</option>
            <option value="GU">GU</option>
            <option value="HI">HI</option>
            <option value="IA">IA</option>
            <option value="ID">ID</option>
            <option value="IL">IL</option>
            <option value="IN">IN</option>
            <option value="KS">KS</option>
            <option value="KY">KY</option>
            <option value="LA">LA</option>
            <option value="MA">MA</option>
            <option value="MD">MD</option>
            <option value="ME">ME</option>
            <option value="MI">MI</option>
            <option value="MN">MN</option>
            <option value="MO">MO</option>
            <option value="MS">MS</option>
            <option value="MT">MT</option>
            <option value="NC">NC</option>
            <option value="ND">ND</option>
            <option value="NE">NE</option>
            <option value="NH">NH</option>
            <option value="NJ">NJ</option>
            <option value="NM">NM</option>
            <option value="NV">NV</option>
            <option value="NY">NY</option>
            <option value="OH">OH</option>
            <option value="OK">OK</option>
            <option value="OR">OR</option>
            <option value="PA">PA</option>
            <option value="PR">PR</option>
            <option value="RI">RI</option>
            <option value="SC">SC</option>
            <option value="SD">SD</option>
            <option value="TN">TN</option>
            <option value="TX">TX</option>
            <option value="UT">UT</option>
            <option value="VA">VA</option>
            <option value="VI">VI</option>
            <option value="VT">VT</option>
            <option value="WA">WA</option>
            <option value="WI">WI</option>
            <option value="WV">WV</option>
            <option value="WY">WY</option>
          </select>
        </div>

        <div className="textFieldContainer">
          <label htmlFor="zip">Zip</label>
          <input
            className="individualFirstName textfieldSmall"
            placeholder="Zip"
            id="zip"
            {...register("zip")}
          />
        </div>
      </div>

      <div className="combineStart">
        <div className="checkBoxFieldContainer marginRight">
          <label htmlFor="isRestitution">Restitution</label>
          <input
            id="isRestitution"
            className="checkbox"
            {...register("isOwedRestitution")}
            type="checkbox"
          />
        </div>

        <div className="textFieldContainer">
          <label htmlFor="restitutionAmount">Restitution Amount</label>
          <input
            placeholder="Restitution Amount"
            className="individualFirstName textField"
            id="restitutionAmount"
            {...register("restitutionAmount")}
          />
        </div>
      </div>
    </div>
  );
}

export default BusinessVictimOptionsForm;
