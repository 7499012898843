import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Header from "./components/views/Common/rendered/Header/Header";
import Nav from "./components/views/Common/rendered/Navigation/Nav";
import CreateIncidentForm from "./components/views/CreateIncident/CreateIncidentForm/CreateIncidentForm";
import CreateNarrativeForm from "./components/views/CreateNarrative/CreateNarrative";
import CreateNewCase from "./components/views/CreateNewCase/CreateNewCase";
import CreateOfficerForm from "./components/views/CreateOfficer/CreateOfficer";
import CreateRequestAccessForm from "./components/views/CreateRequestAccess/CreateRequestAccess";
import CreateSuspectForm from "./components/views/CreateSuspect/CreateSuspect";
import CreateVictimForm from "./components/views/CreateVictim/CreateVictimForm";
import Dashboard from "./components/views/Dashboard/Dashboard";
import DocumentsView from "./components/views/Documents/DocumentsView";
import ForgotPassword from "./components/views/ForgotPassword/ForgotPassword";
import Landing from "./components/views/Landing/Landing";
import Overview from "./components/views/OverView/OverView";
import UpdateUser from "./components/views/UpdateUser/UpdateUser";
import WitnessesView from "./components/views/Witnesses/WitnessesView";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "./App.css";
import RequestAccess from "./components/views/RequestAccess/RequestAccess";
import { persistor, store } from "./redux/store";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="App">
          <Header />
          {window.location.href === "http://localhost:3000/" ||
          window.location.href === "http://localhost:3000/forgotPassword" ||
          window.location.href === "http://localhost:3000/requestAccess" ||
          window.location.href === "http://localhost:3000/dashboard" ||
          // window.location.href ===
          //   "https://portal.ms1da.mossrockhosting.cloud/" ||
          // window.location.href ===
          //   "https://portal.ms1da.mossrockhosting.cloud/forgotPassword" ||
          // window.location.href ===
          //   "https://portal.ms1da.mossrockhosting.cloud/requestAccess" ||
          // window.location.href ===
          //   "https://portal.ms1da.mossrockhosting.cloud/dashboard" ? (
          //   ""
          window.location.href === "https://portal.ms1da.shchosting.cloud/" ||
          window.location.href ===
            "https://portal.ms1da.shchosting.cloud/forgotPassword" ||
          window.location.href ===
            "https://portal.ms1da.shchosting.cloud/requestAccess" ||
          window.location.href ===
            "https://portal.ms1da.shchosting.cloud/dashboard" ? (
            ""
          ) : (
            <Nav />
          )}
          <div className="setMaxWidth">
            <Router>
              <Routes>
                {<Route path="/requestAccess" element={<RequestAccess />} />}
                {<Route path="/" element={<Landing />} />}

                {<Route path="/case" element={<CreateNewCase />} />}
                {<Route path="/dashboard" element={<Dashboard />} />}
                {
                  <Route
                    path="/:id/incident"
                    element={<CreateIncidentForm />}
                  />
                }
                {
                  <Route
                    path="/:id/narrative"
                    element={<CreateNarrativeForm />}
                  />
                }
                {<Route path="/:id/updateUser" element={<UpdateUser />} />}
                {<Route path="/:id/officer" element={<CreateOfficerForm />} />}
                {<Route path="/forgotPassword" element={<ForgotPassword />} />}
                {<Route path="/:id/witnesses" element={<WitnessesView />} />}
                {<Route path="/:id/documents" element={<DocumentsView />} />}
                {
                  <Route
                    path="/requestAccess"
                    element={<CreateRequestAccessForm />}
                  />
                }
                {<Route path="/:id/suspect" element={<CreateSuspectForm />} />}
                {<Route path="/:id/victims" element={<CreateVictimForm />} />}
                {<Route path="/overview" element={<Overview />} />}
              </Routes>
            </Router>
          </div>
          <div className="logoContainer">
            <div className="poweredBy">Powered by</div>
            <div className="daCaseworksLogo"></div>
          </div>
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
