import "./styles.css";

function CreateNewCaseForm() {
  return (
    <div className="newCaseForm">
      <div className="textFieldContainer">
        <label htmlFor="caseNumber">New Case Number</label>
        <input
          type=""
          className="individualFirstName textField"
          id="caseNumber"
        />
      </div>
      {/* <button className="startCaseBtn" onClick={this.handleSubmit}>
        Start
      </button> */}
    </div>
  );
}

export default CreateNewCaseForm;
