import { useState } from "react";
import { useForm } from "react-hook-form";
import { RotatingLines } from "react-loader-spinner";
import "./styles.css";

type LoginAccount = {
  Email: string;
  password: string;
};

function ForgotPassword() {
  const [errorMessage, setErrorMessage] = useState("");
  const [isSendingResetPasswordRequest, setSendingResetPasswordRequest] =
    useState(false);
  const { register, handleSubmit, formState } = useForm<LoginAccount>();

  const onSubmitForgotPassword = handleSubmit(async (data, event) => {
    // setSendingResetPasswordRequest(true);
    // event?.preventDefault();
    // const emailData = {
    //   email: data.Email,
    // };
    // try {
    //   axios.defaults.headers.post = {
    //     "Content-Type": "application/json",
    //   };
    //   const sendResetPasswordEmail = await axiosClient.post(
    //     `/users/forgotPassword`,
    //     emailData
    //   );
    //   if (sendResetPasswordEmail.status === 200) {
    //     setSendingResetPasswordRequest(false);
    //     window.location.href = "/";
    //   }
    // } catch (error) {
    //   setSendingResetPasswordRequest(false);
    //   if (error instanceof axios.AxiosError) {
    //     if (error.response?.data.message === "No account found") {
    //       setErrorMessage(
    //         "No Account found for the provided Email, please try again."
    //       );
    //     }
    //     if (error.response?.status === 400) {
    //       setErrorMessage("Whoops! Something went wrong, please try again.");
    //     }
    //   }
    // }
  });

  const { errors } = formState;

  return (
    <div className="ForgotPassword">
      <div className="ForgotPasswordContainer">
        <div className="forgotPasswordHeaderContainer">
          <div className="forgotPasswordHeader">Forgot Password</div>
        </div>

        <div className="forgotPasswordInputContainer">
          <div className="textFieldContainer">
            <label className="textFieldLabel" htmlFor="email">
              Email
            </label>
            <input type="" className="textField" id="email" />
            {errors.Email && errors?.Email.message}
          </div>

          <div className="forgotPasswordButtonContainer">
            <button
              className="submitPasswordButton"
              onClick={() => onSubmitForgotPassword()}
            >
              Send Reset Link
            </button>
            <span
              className="returnToLoginButton"
              onClick={() => (window.location.href = "/")}
            >
              Return to Login
            </span>
          </div>
        </div>

        {isSendingResetPasswordRequest ? (
          <div className="loginLoaderContainer">
            <RotatingLines
              strokeColor="rgb(17,28,59)"
              strokeWidth="5"
              width="20"
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default ForgotPassword;
