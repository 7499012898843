import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { Suspect } from "../../Types/index";
import type { RootState } from "../store";

interface SuspectState {
  suspect: Suspect | null;
}

const initialState: SuspectState = { suspect: null };

export const SuspectSlice = createSlice({
  name: "suspect",
  initialState,
  reducers: {
    setSuspect: (state, action: PayloadAction<Suspect>) => {
      state.suspect = action.payload;
    },
  },
});

export const { setSuspect } = SuspectSlice.actions;

export const getSuspect = (state: RootState) => state.suspect;

export default SuspectSlice.reducer;
