import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { Narrative } from "../../Types/index";
import type { RootState } from "../store";

interface NarrativeState {
  narrative: Narrative | null;
}

const initialState: NarrativeState = { narrative: null };

export const NarrativeSlice = createSlice({
  name: "incident",
  initialState,
  reducers: {
    setNarrative: (state, action: PayloadAction<Narrative>) => {
      state.narrative = action.payload;
    },
  },
});

export const { setNarrative } = NarrativeSlice.actions;

export const getNarrative = (state: RootState) => state.narrative;

export default NarrativeSlice.reducer;
