import { UpdateIncidentInput } from "@ms1da/server";
import { useFormContext } from "react-hook-form";
import "./styles.css";

function DrugIncidentOptionsForm() {
  const { register } = useFormContext<UpdateIncidentInput>();
  return (
    <div className="DrugIncidentOptionsForm">
      <div className="combineStart">
        <div className="textFieldContainer drugName marginRight">
          <label htmlFor="drugName">Drug Name</label>
          <input
            className="individualFirstName textField"
            placeholder="Drug Name"
            id="drugName"
            {...register("nameOfDrugs")}
          />
        </div>

        <div className="textFieldContainer amount">
          <label htmlFor="amount">Amount</label>
          <input
            className="textfieldSmall "
            {...register("traffickedAmount")}
            placeholder="Amount"
            type={"text"}
            id="amount"
          />
        </div>
      </div>

      <div className="combineStart">
        <div className="checkBoxFieldContainer weaponFound marginRightLong">
          <label htmlFor="weaponFound">Weapon Found</label>
          <input
            type="checkbox"
            className="checkbox"
            {...register("isDrugsFirearmCase")}
            id="weaponFound"
          />
        </div>

        <div className="checkBoxFieldContainer siteLessThan3 marginRightLong">
          <label htmlFor="weaponFound">Site less than 3 miles</label>
          <input
            type="checkbox"
            className="individualFirstName checkbox"
            id="weaponFound"
            {...register("isSchoolDrugsCase")}
          />
        </div>

        <div className="textFieldContainer siteName ">
          <label htmlFor="siteName">Site Name</label>
          <input
            className="individualFirstName textField"
            placeholder="Site Name"
            id="siteName"
            {...register("schoolName")}
          />
        </div>
      </div>
    </div>
  );
}

export default DrugIncidentOptionsForm;
