import { z } from "zod";
import { CaseResponse } from "./response.schema";

export const incidentInput = z.object({
  date: z.string().optional(),
  time: z.string().optional(),
  street: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  zip: z.string().optional(),
  county: z.string().optional(),
  aptNo: z.string().optional(),
  crimeType: z.string().optional(),
  crimeDetails: z.string().optional(),
  isSexCase: z.boolean().optional().default(false),
  isDomesticViolenceCase: z.boolean().optional().default(false),
  isDrugsFirearmCase: z.boolean().optional().default(false),
  isSchoolDrugsCase: z.boolean().optional().default(false),
  schoolName: z.string().optional(),
  charge: z.string(),
  arrestTrackingNumber: z.string().optional(),
  propertyTaken: z.string().optional(),
  propertyValue: z.string().optional(),
  nameOfDrugs: z.string().optional(),
  traffickedAmount: z.string().optional(),
  toxNumber: z.string().optional(),
  chainOfCustody: z.string().optional(),
  sexCrimeType: z.string().optional(),
  isRapeKitIncluded: z.boolean().optional().default(false),
});

const payload = {
  body: incidentInput,
};

const params = {
  params: z.object({
    caseId: z.string({ required_error: "caseId is required" }),
  }),
};

export const updateIncidentRequest = z.object({
  ...params,
  ...payload,
});

export type Incident = z.TypeOf<typeof incidentInput>;

export type UpdateIncidentInput = UpdateIncidentRequest["body"];
export type UpdateIncidentRequest = z.TypeOf<typeof updateIncidentRequest>;
export type UpdateIncidentResponse = CaseResponse;
