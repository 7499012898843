import { Defendant } from "@ms1da/server";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RotatingLines } from "react-loader-spinner";
import { axiosClient } from "../../../axios";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { setSuspect } from "../../../redux/slices/CaseSlice";
import "./styles.css";

function CreateSuspectForm() {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const caseId = useAppSelector((state) => state.case?.case?.id);
  const defendantData = useAppSelector((state) => state.case?.case?.defendant);
  const { register, handleSubmit, reset } = useForm<Defendant>();

  const setDefendantData = (defendantData: Defendant) => {
    let formattedDate = "";
    if (defendantData.dateOfBirth?.split("/")) {
      const splitDate = defendantData.dateOfBirth?.split("/");
      formattedDate = `${splitDate[2]}-${splitDate[0]}-${splitDate[1]}`;
    }
    if (defendantData !== null) {
      reset({
        firstName: defendantData.firstName,
        middleName: defendantData.middleName,
        lastName: defendantData.lastName,
        alias: defendantData.alias,
        relationToVictim: defendantData.relationToVictim,
        address: defendantData.address,
        apartmentNumber: defendantData.apartmentNumber,
        city: defendantData.city,
        state: defendantData.state,
        zip: defendantData.zip,
        race: defendantData.race,
        sex: defendantData.sex,
        dateOfBirth: formattedDate,
        hairColor: defendantData.hairColor,
        eyeColor: defendantData.eyeColor,
        heightFeet: defendantData.heightFeet,
        heightInches: defendantData.heightInches,
        weight: defendantData.weight,
        ssn: defendantData.ssn,
        isSuspectInCustody: defendantData.isSuspectInCustody,
        hasPriorArrests: defendantData.hasPriorArrests,
        custodyLocation: defendantData.custodyLocation,
        coDefendantName: defendantData.coDefendantName,
        driversLicenseNumber: defendantData.driversLicenseNumber,
        driversLicenseState: defendantData.driversLicenseState,
      });
    }
  };

  const submit = handleSubmit(async (data) => {
    setIsLoading(true);
    if (data.firstName === "") {
      alert("Suspect first name is required");
      setIsLoading(false);
      return;
    }
    if (data.middleName === "") {
      alert("Suspect middle name is required");
      setIsLoading(false);
      return;
    }
    if (data.lastName === "") {
      alert("Suspect last name is required");
      setIsLoading(false);
      return;
    }

    if (data.dateOfBirth) {
      let date = data.dateOfBirth.split("-");
      const structuredDate = `${date[1]}/${date[2]}/${date[0]}`;
      data.dateOfBirth = structuredDate;
    }

    dispatch(setSuspect(data));

    try {
      const createIncident = await axiosClient.put(
        `/cases/${caseId}/defendant`,
        data,
        {
          headers: {
            Authorization: `Bearer ${window.sessionStorage.accessToken}`,
            "x-refresh-token": window.sessionStorage.refreshToken,
          },
        }
      );
      if (createIncident.status === 200) {
        const userId = window.sessionStorage.userId;
        setIsLoading(false);
        window.location.href = `/${userId}/victims`;
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  });

  const back = () => {
    const userId = window.sessionStorage.userId;
    window.location.href = `/${userId}/narrative`;
  };

  useEffect(() => {
    if (defendantData !== null && defendantData) {
      setDefendantData(defendantData);
    }
  }, []);

  return (
    <div className="FormWrapper">
      <div className="fullWrapper">
        <div className="IncidentHeader">Suspect</div>
        <div className="suspectFormContainer">
          <div className="combineStart">
            <div className="textFieldContainer marginRight">
              <label htmlFor="firstName">
                First name<span className="required">*</span>
              </label>
              <input
                type=""
                placeholder="First Name"
                className="individualFirstName textField"
                id="firstName"
                {...register("firstName")}
              />
            </div>

            <div className="textFieldContainer marginRight">
              <label htmlFor="middleName">
                Middle name<span className="required">*</span>
              </label>
              <input
                type=""
                placeholder="Middle Name"
                className="individualFirstName textField"
                id="middleName"
                {...register("middleName")}
              />
            </div>
          </div>

          <div className="combineStart">
            <div className="textFieldContainer marginRight">
              <label htmlFor="lastName">
                Last name<span className="required">*</span>
              </label>
              <input
                type=""
                placeholder="Last Name"
                className="individualFirstName textField"
                id="lastName"
                {...register("lastName")}
              />
            </div>

            <div className="textFieldContainer marginRight">
              <label htmlFor="alias">Alias</label>
              <input
                type=""
                placeholder="Alias"
                className="individualFirstName textfieldSmall"
                id="alias"
                {...register("alias")}
              />
            </div>
          </div>

          <div className="textFieldContainer marginRight">
            <label htmlFor="address">Address</label>
            <input
              type=""
              placeholder="Address"
              className="individualFirstName textFieldLong"
              id="address"
              {...register("address")}
            />
          </div>

          <div className="combineStart">
            <div className="textFieldContainer marginRight">
              <label htmlFor="apartmentNumber">Apt No.</label>
              <input
                type=""
                placeholder="Apt No."
                className="individualFirstName textfieldSmall"
                id="apartmentNumber"
                {...register("apartmentNumber")}
              />
            </div>

            <div className="textFieldContainer marginRight">
              <label htmlFor="city">City</label>
              <input
                type=""
                placeholder="City"
                className="individualFirstName textField"
                id="city"
                {...register("city")}
              />
            </div>
          </div>

          <div className="combineStart">
            <div className="textFieldContainer marginRight state">
              <label htmlFor="state">State</label>
              <select
                className="individualFirstName selectFieldSmall"
                id="state"
                {...register("state")}
              >
                {" "}
                <option value="" disabled selected>
                  State
                </option>
                <option value="AK">AK</option>
                <option value="AL">AL</option>
                <option value="AR">AR</option>
                <option value="AS">AS</option>
                <option value="AZ">AZ</option>
                <option value="CA">CA</option>
                <option value="CO">CO</option>
                <option value="CT">CT</option>
                <option value="DC">DC</option>
                <option value="DE">DE</option>
                <option value="FL">FL</option>
                <option value="GA">GA</option>
                <option value="GU">GU</option>
                <option value="HI">HI</option>
                <option value="IA">IA</option>
                <option value="ID">ID</option>
                <option value="IL">IL</option>
                <option value="IN">IN</option>
                <option value="KS">KS</option>
                <option value="KY">KY</option>
                <option value="LA">LA</option>
                <option value="MA">MA</option>
                <option value="MD">MD</option>
                <option value="ME">ME</option>
                <option value="MI">MI</option>
                <option value="MN">MN</option>
                <option value="MO">MO</option>
                <option value="MS">MS</option>
                <option value="MT">MT</option>
                <option value="NC">NC</option>
                <option value="ND">ND</option>
                <option value="NE">NE</option>
                <option value="NH">NH</option>
                <option value="NJ">NJ</option>
                <option value="NM">NM</option>
                <option value="NV">NV</option>
                <option value="NY">NY</option>
                <option value="OH">OH</option>
                <option value="OK">OK</option>
                <option value="OR">OR</option>
                <option value="PA">PA</option>
                <option value="PR">PR</option>
                <option value="RI">RI</option>
                <option value="SC">SC</option>
                <option value="SD">SD</option>
                <option value="TN">TN</option>
                <option value="TX">TX</option>
                <option value="UT">UT</option>
                <option value="VA">VA</option>
                <option value="VI">VI</option>
                <option value="VT">VT</option>
                <option value="WA">WA</option>
                <option value="WI">WI</option>
                <option value="WV">WV</option>
                <option value="WY">WY</option>
              </select>
            </div>

            <div className="textFieldContainer marginRight">
              <label htmlFor="zip">Zip</label>
              <input
                type=""
                placeholder="Zip"
                className="individualFirstName textfieldSmall"
                id="zip"
                {...register("zip")}
              />
            </div>
          </div>

          <div className="textFieldContainer marginRight">
            <label htmlFor="relationToVictim">Victim Relation</label>
            <input
              type=""
              placeholder="Victim Relation"
              className="individualFirstName textField"
              id="relationToVictim"
              {...register("relationToVictim")}
            />
          </div>

          <div className="combineStart">
            <div className="textFieldContainer marginRight">
              <label htmlFor="race">Race</label>
              <select
                className="individualFirstName textfieldSmall"
                id="race"
                {...register("race")}
              >
                {" "}
                <option value="" disabled selected>
                  Race
                </option>
                <option value="Asian">Asian</option>
                <option value="Black">Black</option>
                <option value="White">White</option>
                <option value="Indian">Indian</option>
                <option value="Hispanic">Hispanic</option>
                <option value="Unknown">Unknown</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div className="textFieldContainer marginRight">
              <label htmlFor="sex">Sex</label>
              <select
                className="individualFirstName textfieldSmall"
                {...register("sex")}
                id="sex"
              >
                {" "}
                <option value="" disabled selected>
                  Sex
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Unknown">Unknown</option>
              </select>
            </div>

            <div className="textFieldContainer marginRight">
              <label htmlFor="dateOfBirth">Date Of Birth</label>
              <input
                type="date"
                className="individualFirstName textfieldSmall"
                id="dateOfBirth"
                {...register("dateOfBirth")}
              />
            </div>
          </div>

          <div className="combineStart">
            <div className="textFieldContainer marginRight">
              <label htmlFor="hairColor">Hair Color</label>
              <select
                className="individualFirstName textfieldSmall"
                id="hairColor"
                {...register("hairColor")}
              >
                {" "}
                <option value="" disabled selected>
                  Color
                </option>
                <option value="Black">Black</option>
                <option value="Blonde">Blonde</option>
                <option value="Brown">Brown</option>
                <option value="Grey">Grey</option>
                <option value="White">White</option>
                <option value="Red">Red</option>
                <option value="Bald">Bald</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div className="textFieldContainer marginRight">
              <label htmlFor="eyeColor">Eye Color</label>
              <select
                className="individualFirstName textfieldSmall"
                id="eyeColor"
                {...register("eyeColor")}
              >
                {" "}
                <option value="" disabled selected>
                  Color
                </option>
                <option value="Brown">Brown</option>
                <option value="Hazel">Hazel</option>
                <option value="Grey">Grey</option>
                <option value="Green">Green</option>
                <option value="Blue">Blue</option>
                <option value="Black">Black</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>

          <div className="combineStart">
            <div className="textFieldContainer marginRight">
              <label htmlFor="heightFeet">Height Ft</label>
              <input
                placeholder="Height Ft"
                className="individualFirstName textfieldSmall"
                id="heightFeet"
                {...register("heightFeet")}
              />
            </div>

            <div className="textFieldContainer marginRight">
              <label htmlFor="heightInches">Height In</label>
              <input
                placeholder="Height In"
                className="individualFirstName textfieldSmall"
                id="heightInches"
                {...register("heightInches")}
              />
            </div>

            <div className="textFieldContainer marginRight">
              <label htmlFor="weight">Weight</label>
              <input
                placeholder="Weight"
                className="individualFirstName textfieldSmall"
                id="weight"
                {...register("weight")}
              />
            </div>
          </div>

          <div className="combineStart">
            <div className="textFieldContainer marginRight">
              <label htmlFor="socialSecurityNumber">
                Social Security Number
              </label>
              <input
                placeholder="SSN"
                className="individualFirstName textfieldSmall"
                id="socialSecurityNumber"
                {...register("ssn")}
              />
            </div>
          </div>

          <div className="combineStart">
            <div className="textFieldContainer marginRight">
              <label htmlFor="driversLicenseNumber">
                Drivers License Number
              </label>
              <input
                placeholder="License Number"
                className="individualFirstName textfieldSmall"
                id="driversLicenseNumber"
                {...register("driversLicenseNumber")}
              />
            </div>

            <div className="textFieldContainer marginRight">
              <label htmlFor="driversLicenseState">Drivers License State</label>
              <select
                // placeholder="License State"
                className="individualFirstName textfieldSmall"
                id="driversLicenseState"
                {...register("driversLicenseState")}
              >
                {" "}
                <option value="" disabled selected>
                  State
                </option>
                <option value="AK">AK</option>
                <option value="AL">AL</option>
                <option value="AR">AR</option>
                <option value="AS">AS</option>
                <option value="AZ">AZ</option>
                <option value="CA">CA</option>
                <option value="CO">CO</option>
                <option value="CT">CT</option>
                <option value="DC">DC</option>
                <option value="DE">DE</option>
                <option value="FL">FL</option>
                <option value="GA">GA</option>
                <option value="GU">GU</option>
                <option value="HI">HI</option>
                <option value="IA">IA</option>
                <option value="ID">ID</option>
                <option value="IL">IL</option>
                <option value="IN">IN</option>
                <option value="KS">KS</option>
                <option value="KY">KY</option>
                <option value="LA">LA</option>
                <option value="MA">MA</option>
                <option value="MD">MD</option>
                <option value="ME">ME</option>
                <option value="MI">MI</option>
                <option value="MN">MN</option>
                <option value="MO">MO</option>
                <option value="MS">MS</option>
                <option value="MT">MT</option>
                <option value="NC">NC</option>
                <option value="ND">ND</option>
                <option value="NE">NE</option>
                <option value="NH">NH</option>
                <option value="NJ">NJ</option>
                <option value="NM">NM</option>
                <option value="NV">NV</option>
                <option value="NY">NY</option>
                <option value="OH">OH</option>
                <option value="OK">OK</option>
                <option value="OR">OR</option>
                <option value="PA">PA</option>
                <option value="PR">PR</option>
                <option value="RI">RI</option>
                <option value="SC">SC</option>
                <option value="SD">SD</option>
                <option value="TN">TN</option>
                <option value="TX">TX</option>
                <option value="UT">UT</option>
                <option value="VA">VA</option>
                <option value="VI">VI</option>
                <option value="VT">VT</option>
                <option value="WA">WA</option>
                <option value="WI">WI</option>
                <option value="WV">WV</option>
                <option value="WY">WY</option>
              </select>
            </div>
          </div>

          <div className="combineStart">
            <div className="checkBoxFieldContainer marginRightExtraLong">
              <label htmlFor="hasPriors">Has Priors</label>
              <input
                type="CheckBox"
                className="individualFirstName checkbox"
                id="hasPriors"
                {...register("hasPriorArrests")}
              />
            </div>
            <div className="checkBoxFieldContainer marginRightLong">
              <label htmlFor="inCustody">In Custody</label>
              <input
                type="CheckBox"
                className="individualFirstName checkbox"
                id="inCustody"
                {...register("isSuspectInCustody")}
              />
            </div>

            <div className="textFieldContainer marginRight">
              <label htmlFor="custodyLocation">Custody Location</label>
              <input
                className="individualFirstName textField"
                id="custodyLocation"
                placeholder="Custody Location"
                {...register("custodyLocation")}
              />
            </div>
          </div>

          <div className="textFieldContainer marginRight">
            <label htmlFor="codefendantName">Codefendant Name</label>
            <input
              placeholder="Codefendant Name"
              className="individualFirstName textField"
              id="codefendantName"
              {...register("coDefendantName")}
            />
          </div>
        </div>
        <div className="navigateSectionsContainer">
          <button className="navigateSectionsButton" onClick={() => back()}>
            Back
          </button>
          {isLoading && (
            <div className="loginLoaderContainer">
              <RotatingLines
                strokeColor="rgb(17,28,59)"
                strokeWidth="5"
                width="20"
              />
            </div>
          )}
          <button className="navigateSectionsButton" onClick={() => submit()}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateSuspectForm;
