import { UpdateIncidentInput } from "@ms1da/server";
import { useFormContext } from "react-hook-form";

function SexIncidentOptionsForm() {
  const { register } = useFormContext<UpdateIncidentInput>();
  return (
    <div className="SexIncidentOptionsForm">
      <div className="combine">
        <div className="textFieldContainer">
          <label htmlFor="sexCrime">Sex Crime</label>
          <input
            type="checkbox"
            className="individualFirstName textfieldSmall"
            {...register("isSexCase")}
            id="sexCrime"
          />
        </div>

        <div className="textFieldContainer">
          <label htmlFor="domesticViolence">Domestic Violence</label>
          <input
            type="checkbox"
            className="individualFirstName checkbox"
            id="domesticViolence"
            {...register("isDomesticViolenceCase")}
          />
        </div>

        <div className="textFieldContainer">
          <label htmlFor="rapeKit">Rape Kit</label>
          <input
            type="checkbox"
            className="individualFirstName checkbox"
            id="rapeKit"
            {...register("isRapeKitIncluded")}
          />
        </div>
      </div>
    </div>
  );
}

export default SexIncidentOptionsForm;
