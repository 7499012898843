import { z } from "zod";
import { CaseResponse } from "./response.schema";

const officerCore = {
  personSigningWarrant: z.string().optional(),
  officerInCharge: z.string().optional(),
  jurisdiction: z.string().optional(),
  badgeNumber: z.string().optional(),
  department: z.string().optional(),
  caseNumber: z.string().optional(),
  extension: z.string().optional(),
  // email: z.string().email("Invalid email format").optional(),
  email: z.string().optional(),
  phoneCell: z.string().optional(),
  phoneHome: z.string().optional(),
  phoneWork: z.string().optional(),
};

const params = {
  params: z.object({
    caseId: z.string({ required_error: "caseId is required" }),
  }),
};

export const updateOfficerSchema = z.object({
  ...params,
  body: z.object({
    ...officerCore,
  }),
});

export const officerSchema = z.object({ ...officerCore });

export type UpdateOfficerRequest = z.TypeOf<typeof updateOfficerSchema>;
export type UpdateOfficerInput = UpdateOfficerRequest["body"];
export type UpdateOfficerResponse = CaseResponse;

export type Officer = UpdateOfficerInput;
