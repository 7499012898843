import { UpdateVictimInput } from "@ms1da/server";
import { useFormContext } from "react-hook-form";

function IndividualVictimOptionsForm() {
  const { register, reset } = useFormContext<UpdateVictimInput>();

  const formatSSN = (value: any) => {
    if (!value) return value;
    const ssn = value.replace(/[^\d]/g, "");
    const ssnLength = ssn.length;

    if (ssnLength < 4) return ssn;

    if (ssnLength < 6) {
      return `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
    }

    return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 8)}`;
  };

  const ssnFormatter = (event: any) => {
    const inputField = document.getElementById("ssn");

    const formattedInputValue = formatSSN(event.target.value);
  };

  return (
    <div className="IndividualVictimOptionsForm">
      <div className="IndividualFormContainer">
        <div className="combineStart">
          <div className="textFieldContainer marginRight">
            <label htmlFor="firstName">First Name</label>
            <input
              placeholder="First Name"
              className="individualFirstName textField"
              id="firstName"
              {...register("firstName")}
            />
          </div>

          <div className="textFieldContainer">
            <label htmlFor="firstName">Middle Name</label>
            <input
              placeholder="Middle Name"
              className="individualMiddleName textField"
              id="middleName"
              {...register("middleName")}
            />
          </div>
        </div>

        <div className="textFieldContainer">
          <label htmlFor="firstName">Last Name</label>
          <input
            placeholder="Last Name"
            className="individualLastName textField"
            id="lastName"
            {...register("lastName")}
          />
        </div>

        <div className="combineStart">
          <div className="textFieldContainer marginRight">
            <label htmlFor="firstName">Race</label>
            <select
              className="individualRace textfieldSmall"
              id="race"
              {...register("race")}
              // options={raceOptions}
            >
              {" "}
              <option value="" disabled selected>
                Race
              </option>
              <option value="Asian">Asian</option>
              <option value="Black">Black</option>
              <option value="White">White</option>
              <option value="Indian">Indian</option>
              <option value="Hispanic">Hispanic</option>
              <option value="Unknown">Unknown</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <div className="textFieldContainer">
            <label htmlFor="firstName">Sex</label>
            <select
              className="individualSex textfieldSmall"
              id="sex"
              {...register("sex")}
              // options={sexOptions}
            >
              {" "}
              <option value="" disabled selected>
                Sex
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Unknown">Unknown</option>
            </select>
          </div>
        </div>

        <div className="textFieldContainer">
          <label htmlFor="firstName">Address</label>
          <input
            placeholder="Address"
            className="individualStreet textFieldLong"
            id="street"
            {...register("address")}
          />
        </div>

        <div className="combineStart">
          <div className="textFieldContainer marginRight">
            <label htmlFor="firstName">Apt Number</label>
            <input
              placeholder="Apt Number"
              className="individualapartmentNumber textfieldSmall"
              id="apartmentNumber"
              {...register("apartmentNumber")}
            />
          </div>

          <div className="textFieldContainer">
            <label htmlFor="firstName">City</label>
            <input
              placeholder="City"
              className="individualCity textField"
              id="city"
              {...register("city")}
            />
          </div>
        </div>
        <div className="combineStart">
          <div className="textFieldContainer marginRight">
            <label htmlFor="firstName">State</label>
            <select
              className="individualState textField"
              id="state"
              {...register("state")}
              // options={stateOptions}
            >
              {" "}
              <option value="" disabled selected>
                State
              </option>
              <option value="AK">AK</option>
              <option value="AL">AL</option>
              <option value="AR">AR</option>
              <option value="AS">AS</option>
              <option value="AZ">AZ</option>
              <option value="CA">CA</option>
              <option value="CO">CO</option>
              <option value="CT">CT</option>
              <option value="DC">DC</option>
              <option value="DE">DE</option>
              <option value="FL">FL</option>
              <option value="GA">GA</option>
              <option value="GU">GU</option>
              <option value="HI">HI</option>
              <option value="IA">IA</option>
              <option value="ID">ID</option>
              <option value="IL">IL</option>
              <option value="IN">IN</option>
              <option value="KS">KS</option>
              <option value="KY">KY</option>
              <option value="LA">LA</option>
              <option value="MA">MA</option>
              <option value="MD">MD</option>
              <option value="ME">ME</option>
              <option value="MI">MI</option>
              <option value="MN">MN</option>
              <option value="MO">MO</option>
              <option value="MS">MS</option>
              <option value="MT">MT</option>
              <option value="NC">NC</option>
              <option value="ND">ND</option>
              <option value="NE">NE</option>
              <option value="NH">NH</option>
              <option value="NJ">NJ</option>
              <option value="NM">NM</option>
              <option value="NV">NV</option>
              <option value="NY">NY</option>
              <option value="OH">OH</option>
              <option value="OK">OK</option>
              <option value="OR">OR</option>
              <option value="PA">PA</option>
              <option value="PR">PR</option>
              <option value="RI">RI</option>
              <option value="SC">SC</option>
              <option value="SD">SD</option>
              <option value="TN">TN</option>
              <option value="TX">TX</option>
              <option value="UT">UT</option>
              <option value="VA">VA</option>
              <option value="VI">VI</option>
              <option value="VT">VT</option>
              <option value="WA">WA</option>
              <option value="WI">WI</option>
              <option value="WV">WV</option>
              <option value="WY">WY</option>
            </select>
          </div>

          <div className="textFieldContainer">
            <label htmlFor="firstName">Zip</label>
            <input
              placeholder="Zip"
              className="individualZip textfieldSmall"
              id="zip"
              {...register("zip")}
            />
          </div>
        </div>
        <div className="combineStart">
          <div className="textFieldContainer marginRight">
            <label htmlFor="firstName">DOB</label>
            <input
              className="individualDateOfBirth textfieldSmall"
              type="date"
              id="dob"
              {...register("dateOfBirth")}
            />
          </div>

          <div className="textFieldContainer">
            <label htmlFor="firstName">Social Security Number</label>
            <input
              placeholder="SSN"
              className="individualSSN textfieldSmall"
              id="ssn"
              type={"password"}
              {...register("ssn", {
                onChange(event) {
                  ssnFormatter(event);
                },
              })}
            />
          </div>
        </div>

        <div className="combineStart">
          {/* <div className="textFieldContainer marginRight">
            <label htmlFor="firstName">Home Phone</label>
            <input
              placeholder="Home Phone"
              className="individualPhoneHome textfieldSmall"
              id="phoneHome"
              {...register("phoneHome")}
            />
          </div> */}
          <div className="textFieldContainer marginRight">
            <label htmlFor="firstName">Email</label>
            <input
              placeholder="Email"
              className="individualEmail textField"
              id="email"
              {...register("email")}
            />
          </div>

          <div className="textFieldContainer">
            <label htmlFor="firstName">Cell Phone</label>
            <input
              placeholder="Cell Phone"
              className="individualPhoneCell textfieldSmall"
              id="phoneCell"
              {...register("phoneCell")}
            />
          </div>
        </div>

        <div className="combineStart">
          <div className="checkBoxFieldContainer marginRight">
            <label htmlFor="firstName">Priors</label>
            <input
              id="hasPriors"
              type="checkbox"
              {...register("hasPriors")}
              className="checkbox"
            />
          </div>

          <div className="checkBoxFieldContainer">
            <label htmlFor="firstName">Restitution</label>
            <input
              className="individualIsRestitution checkbox"
              id="isResitution"
              type="checkbox"
              {...register("isOwedRestitution")}
            />
          </div>
        </div>

        <div className="textFieldContainer">
          <label htmlFor="firstName">Restitution Amount</label>
          <input
            placeholder="Restitution Amount"
            className="individualRestitutionAmount textField"
            id="restitutionAmount"
            {...register("restitutionAmount")}
          />
        </div>
      </div>
    </div>
  );
}
export default IndividualVictimOptionsForm;
