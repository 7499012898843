import "./styles.css";

function Overview() {
  return (
    <div className="Overview">
      {/* <div className="overviewOuterGrid">
        <div className="officerInfo">
          <div id="overviewOfficerName">
            <span className="overviewLabel primaryLabel">
              Officer In Charge:{" "}
            </span>
            <span className="overviewValue">{`${this.user.firstName} ${this.user.lastName}`}</span>
          </div>
          <div id="overviewAgency">
            <span className="overviewLabel">Agency: </span>
            <span className="overviewValue">{`${
              this.user.agency || "Test Agency"
            }`}</span>
          </div>
          <div id="overviewCaseNumber">
            <span className="overviewLabel">Case Number: </span>
            <span className="overviewValue">{`${this.case.caseNumber}`}</span>
          </div>
          <div id="overviewPhone">
            <span className="overviewLabel">Phone: </span>
            <span className="overviewValue">{`${
              this.user.phoneNumber || "TEST Phone Number"
            }`}</span>
          </div>
          <div id="overviewPersonSigningWarrnt">
            <span className="overviewLabel">Person Signing Warrant: </span>
            <span className="overviewValue">{`${this.user.firstName} ${this.user.lastName}`}</span>
          </div>
          <div id="overviewCharge">
            <span className="overviewLabel">Charge: </span>
            <span className="overviewValue">{`${this.case.incident.charge}`}</span>
          </div>
        </div>

        <div className="suspectStatus">
          <div className="missingFields">
            {" "}
            {this.renderSuspectMissingFields()}
          </div>
        </div>

        <div className="suspectInfo">
          <div id="overviewSuspectName">
            <span className="overviewLabel primaryLabel">Suspect: </span>
            <span className="overviewValue">{`${this.case.suspect.firstName} ${this.case.suspect.middleName} ${this.case.suspect.lastName}`}</span>
          </div>
          <div id="overviewSuspectAlias">
            <span className="overviewLabel">Alias: </span>
            <span className="overviewValue">{`${this.case.suspect.alias}`}</span>
          </div>
          <div id="overviewSuspectAddress">
            <span className="overviewLabel">Address: </span>
            <span className="overviewValue">{`${this.case.suspect.address} ${this.case.suspect.apartmentNumber} ${this.case.suspect.city}, ${this.case.suspect.state} ${this.case.suspect.zip}`}</span>
          </div>
          <div id="overviewSuspectDateOfBirth">
            <span className="overviewLabel">DOB: </span>
            <span className="overviewValue">{`${this.case.suspect.dateOfBirth}`}</span>
          </div>
          <div id="overviewSuspectSocialSecurityNumber">
            <span className="overviewLabel">SSN: </span>
            <span className="overviewValue">{`${this.case.suspect.socialSecurityNumber}`}</span>
          </div>
          <div id="overviewSuspectRace">
            <span className="overviewLabel">Race: </span>
            <span className="overviewValue">{`${this.case.suspect.race}`}</span>
          </div>
          <div id="overviewSuspectSex">
            <span className="overviewLabel">Sex: </span>
            <span className="overviewValue">{`${this.case.suspect.sex}`}</span>
          </div>
          <div id="overviewSuspectHairColor">
            <span className="overviewLabel">Hair: </span>
            <span className="overviewValue">{`${this.case.suspect.hairColor}`}</span>
          </div>
          <div id="overviewSuspectEyeColor">
            <span className="overviewLabel">Eyes: </span>
            <span className="overviewValue">{`${this.case.suspect.eyeColor}`}</span>
          </div>
          <div id="overviewSuspectHeight">
            <span className="overviewLabel">Height: </span>
            <span className="overviewValue">{`${this.case.suspect.heightFeet} FT ${this.case.suspect.heightInches} IN`}</span>
          </div>
          <div id="overviewSuspectWeight">
            <span className="overviewLabel">Weight: </span>
            <span className="overviewValue">{`${this.case.suspect.weight}`}</span>
          </div>
          <div id="overviewSuspectInCustody">
            <span className="overviewLabel">Suspect In Custody: </span>
            <span className="overviewValue">{`${
              this.case.suspect.inCustody ? "YES" : "NO"
            }`}</span>
          </div>
          <div id="overviewSuspectCustodyLocation">
            <span className="overviewLabel">Custody Location: </span>
            <span className="overviewValue">{`${this.case.suspect.custodyLocation}`}</span>
          </div>
        </div>

        <div className="codefendantStatus">
          <Link to={`/case/${this.case.id}/suspect/`}>
            <button className="statusButton">Edit</button>
          </Link>
        </div>

        <div className="codefendantInfo">
          <div id="overviewCodefendantNames">
            <span className="overviewLabel primaryLabel">
              Co-Defendant(s):{" "}
            </span>
            <span className="overviewValue">{`${this.case.suspect.codefendantName}`}</span>
          </div>
        </div>

        <div className="victimStatus">
          <Link to={`/case/${this.case.id}/victim/`}>
            <button className="statusButton">Edit</button>
          </Link>
          <div className="missingFields">
            {" "}
            {this.renderVictimMissingFields()}{" "}
          </div>
        </div>

        <div className="victimInfo">
          <div id="overviewVictimName">
            <span className="overviewLabel primaryLabel">Victim: </span>
            <span className="overviewValue">
              {this.case.victim.type === "Individual"
                ? `${this.case.victim.firstName} ${this.case.victim.middleName} ${this.case.victim.lastName}`
                : `${this.case.victim.businessName || "State of Alabama"}`}
            </span>
          </div>
          <div id="overviewVictimDateOfBirth">
            <span className="overviewLabel">DOB: </span>
            <span className="overviewValue">{`${
              this.case.victim.dateOfBirth || ""
            }`}</span>
          </div>
          <div id="overviewVictimRace">
            <span className="overviewLabel">Race: </span>
            <span className="overviewValue">{`${
              this.case.victim.race || ""
            }`}</span>
          </div>
          <div id="overviewVictimSex">
            <span className="overviewLabel">Sex: </span>
            <span className="overviewValue">{`${
              this.case.victim.sex || ""
            }`}</span>
          </div>
          <div id="overviewVictimSocialSecurity">
            <span className="overviewLabel">SSN: </span>
            <span className="overviewValue">{`${
              this.case.victim.socialSecurityNumber || ""
            }`}</span>
          </div>
          <div id="overviewVictimAddress">
            <span className="overviewLabel">Address: </span>
            <span className="overviewValue">{`${this.case.victim.street} ${this.case.victim.apartmentNumber} ${this.case.victim.city}, ${this.case.victim.state} ${this.case.victim.zip}`}</span>
          </div>
          <div id="overviewVictimPhone1">
            <span className="overviewLabel">Phone 1: </span>
            <span className="overviewValue">{`${
              this.case.victim.phoneHome || this.case.victim.contactPhone || ""
            }`}</span>
          </div>
          <div id="overviewVictimPhon21">
            <span className="overviewLabel">Phone 2: </span>
            <span className="overviewValue">{`${
              this.case.victim.phoneCell || ""
            }`}</span>
          </div>
          <div id="overviewVictimEmail">
            <span className="overviewLabel">Email: </span>
            <span className="overviewValue">{`${
              this.case.victim.email || ""
            }`}</span>
          </div>
        </div>
      </div>

      <div className="caseOverviewSubmitButton">
        <button className="caseSubmitButton" onClick={this.handleSubmit}>
          Submit
        </button>
      </div> */}
    </div>
  );
}

export default Overview;
