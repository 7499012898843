import { UpdateIncidentInput } from "@ms1da/server";
import { useFormContext } from "react-hook-form";
import "./styles.css";

function PersonIncidentOptionsForm() {
  const { register } = useFormContext<UpdateIncidentInput>();

  return (
    <div className="PersonIncidentOptionsForm">
      <div className="combineStart">
        <div className="textFieldContainer crimeDetails marginRight">
          <label htmlFor="crimeDetails">Crime Details</label>
          <input
            className="individualFirstName textField "
            placeholder="Crime Details"
            id="crimeDetails"
            {...register("crimeDetails")}
          />
        </div>

        <div className="checkBoxFieldContainer">
          <label htmlFor="domesticViolence">Domestic Violence</label>
          <input
            type={"checkbox"}
            className="individualFirstName checkbox"
            id="domesticViolence"
            {...register("isDomesticViolenceCase")}
          />
        </div>
      </div>
    </div>
  );
}

export default PersonIncidentOptionsForm;
