import { UpdateVictimInput } from "@ms1da/server";
import { useFormContext } from "react-hook-form";
import { useAppSelector } from "../../../redux/hook";

function StateVictimOptionsForm() {
  const { register, reset } = useFormContext<UpdateVictimInput>();
  const victimType = useAppSelector(
    (state) => state.case?.case?.victim?.victimType
  );

  let isChecked = false;

  if (victimType === "State Victim") {
    isChecked = true;
  }

  return (
    <div className="StateVictimOptionsForm">
      <div className="combineStart">
        <div className="checkBoxFieldContainer marginRight">
          <label htmlFor="isRestitution">State of Mississippi</label>
          {/* <input
            id="isRestitution"
            className="checkbox"
            onClick={() => fThisISDumb()}
            checked={fThisISDumb()}
            type="checkbox"
          /> */}
        </div>
      </div>
    </div>
  );
}

export default StateVictimOptionsForm;
