import { Dialog } from "@headlessui/react";
import { Witness } from "@ms1da/server";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RotatingLines } from "react-loader-spinner";
import { axiosClient } from "../../../axios";
import { useAppSelector } from "../../../redux/hook";
import WitnessForm from "./WitnessForm";

function WitnessesView() {
  const [witnesses, setWitnesses] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [currentWitnessId, setCurrentWitnessId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, formState, reset } = useForm<Witness>();
  const submit = () => {
    const userId = window.sessionStorage.userId;
    window.location.href = `/${userId}/documents  `;
  };

  const back = () => {
    const userId = window.sessionStorage.userId;
    window.location.href = `/${userId}/victims  `;
  };

  document.addEventListener("witnesses", (event) => {
    renderWitnesses(event);
  });

  const caseId = useAppSelector((state) => state.case?.case?.id);

  const getWitnessDataFromFM = async () => {
    const caseData = await axiosClient.get(`/cases/${caseId}`, {
      headers: {
        Authorization: `Bearer ${window.sessionStorage.accessToken}`,
        "x-refresh-token": window.sessionStorage.refreshToken,
      },
    });

    if (caseData.status === 200) {
      return caseData.data.data.case.witnesses;
    }
  };

  const checkForWitnessesInitially = async () => {
    const checkForWitnesses = await getWitnessDataFromFM();
    if (checkForWitnesses) {
      const witnessJSXArray = checkForWitnesses.map((w: Witness) => {
        return (
          <div className="witnessListItem">
            <div className="witnessListNameContainer">
              <div className="witnessListFirstName">Name: {w.firstName}</div>
              <div className="witnessListFirstName">{w.lastName}</div>
              {w.role ? <div>Role: {w.role}</div> : ""}
            </div>

            <div
              className="witnessListEditButton"
              onClick={() => renderDialogWithWitnessData(w)}
            >
              Edit
            </div>
          </div>
        );
      });

      setWitnesses(witnessJSXArray);
    }
  };

  useEffect(() => {
    checkForWitnessesInitially();
  }, []);

  const onUpdateWitness = handleSubmit(async (data) => {
    setIsLoading(true);
    const dataStringified = {
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
      address: data.address,
      city: data.city,
      state: data.state,
      zip: data.zip,
      role: data.role,
      race: data.race,
      sex: data.sex,
      dateOfBirth: data.dateOfBirth,
      ssn: data?.ssn?.toString(),
      email: data.email,
      driversLicenseNumber: data.driversLicenseNumber,
      driversLicenseState: data.driversLicenseState,
    };
    // update witness and clear current witness id
    try {
      let updateWitness = await axiosClient.put(
        `/cases/${caseId}/witnesses/${currentWitnessId}`,
        dataStringified,
        {
          headers: {
            Authorization: `Bearer ${window.sessionStorage.accessToken}`,
            "x-refresh-token": window.sessionStorage.refreshToken,
          },
        }
      );

      if (updateWitness.status === 200) {
        setIsOpen(false);
        setIsLoading(false);
        setCurrentWitnessId("");
        checkForWitnessesInitially();
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  });

  const renderDialogWithWitnessData = (witness: Witness) => {
    setCurrentWitnessId(witness.id);
    setIsOpen(true);
    reset({
      firstName: witness.firstName,
      middleName: witness.middleName,
      lastName: witness.lastName,
      address: witness.address,
      city: witness.city,
      state: witness.state,
      zip: witness.zip,
      role: witness.role,
      race: witness.race,
      sex: witness.sex,
      dateOfBirth: witness.dateOfBirth,
      ssn: witness.ssn,
      email: witness.email,
      driversLicenseNumber: witness.driversLicenseNumber,
      driversLicenseState: witness.driversLicenseState,
    });
  };

  const renderWitnesses = (e: any) => {
    const witnessJSXArray = e.detail.map((w: Witness) => {
      return (
        <div className="witnessListItem">
          <div className="witnessListNameContainer">
            <div className="witnessListFirstName">Name: {w.firstName}</div>
            <div className="witnessListFirstName">{w.lastName}</div>
            {w.role ? <div>Role: {w.role}</div> : ""}
          </div>

          <div
            className="witnessListEditButton"
            onClick={() => renderDialogWithWitnessData(w)}
          >
            Edit
          </div>
        </div>
      );
    });

    setWitnesses(witnessJSXArray);
  };

  return (
    <div className="FormWrapper">
      <div className="fullWrapper">
        <div className="IncidentHeader">Witnesses</div>
        <div className="centerWitnessActionable">
          <Dialog
            className="dialog"
            open={isOpen}
            onClose={() => setIsOpen(true)}
          >
            <Dialog.Panel>
              <Dialog.Title>Witness Form</Dialog.Title>
              <form onSubmit={onUpdateWitness}>
                <div className="witnessFormContainerScroll">
                  <div className="combineStart">
                    <div className="textFieldContainer marginRight">
                      <label htmlFor="firstName">
                        First name<span className="required">*</span>
                      </label>
                      <input
                        type=""
                        placeholder="First Name"
                        className="individualFirstName textfieldSmall"
                        id="firstName"
                        {...register("firstName")}
                      />
                    </div>

                    <div className="textFieldContainer marginRight">
                      <label htmlFor="middleName">Middle name</label>
                      <input
                        type=""
                        placeholder="Middle Name"
                        className="individualFirstName textfieldSmall"
                        id="middleName"
                        {...register("middleName")}
                      />
                    </div>

                    <div className="textFieldContainer">
                      <label htmlFor="lastName">
                        Last name<span className="required">*</span>
                      </label>
                      <input
                        type=""
                        placeholder="Last Name"
                        className="individualFirstName textfieldSmall"
                        id="lastName"
                        {...register("lastName")}
                      />
                    </div>
                  </div>

                  <div className="textFieldContainer">
                    <label htmlFor="address">Address</label>
                    <input
                      type=""
                      placeholder="Address"
                      className="individualFirstName textFieldLong"
                      id="address"
                      {...register("address")}
                    />
                  </div>

                  <div className="combineStart">
                    <div className="textFieldContainer marginRight">
                      <label htmlFor="city">City</label>
                      <input
                        type=""
                        placeholder="City"
                        className="individualFirstName textfieldSmall"
                        id="city"
                        {...register("city")}
                      />
                    </div>

                    <div className="textFieldContainer marginRight">
                      <label htmlFor="state">State</label>
                      <select
                        className="individualFirstName textfieldSmall"
                        id="state"
                        {...register("state")}
                      >
                        {" "}
                        <option value="" disabled selected>
                          State
                        </option>
                        <option value="AK">AK</option>
                        <option value="AL">AL</option>
                        <option value="AR">AR</option>
                        <option value="AS">AS</option>
                        <option value="AZ">AZ</option>
                        <option value="CA">CA</option>
                        <option value="CO">CO</option>
                        <option value="CT">CT</option>
                        <option value="DC">DC</option>
                        <option value="DE">DE</option>
                        <option value="FL">FL</option>
                        <option value="GA">GA</option>
                        <option value="GU">GU</option>
                        <option value="HI">HI</option>
                        <option value="IA">IA</option>
                        <option value="ID">ID</option>
                        <option value="IL">IL</option>
                        <option value="IN">IN</option>
                        <option value="KS">KS</option>
                        <option value="KY">KY</option>
                        <option value="LA">LA</option>
                        <option value="MA">MA</option>
                        <option value="MD">MD</option>
                        <option value="ME">ME</option>
                        <option value="MI">MI</option>
                        <option value="MN">MN</option>
                        <option value="MO">MO</option>
                        <option value="MS">MS</option>
                        <option value="MT">MT</option>
                        <option value="NC">NC</option>
                        <option value="ND">ND</option>
                        <option value="NE">NE</option>
                        <option value="NH">NH</option>
                        <option value="NJ">NJ</option>
                        <option value="NM">NM</option>
                        <option value="NV">NV</option>
                        <option value="NY">NY</option>
                        <option value="OH">OH</option>
                        <option value="OK">OK</option>
                        <option value="OR">OR</option>
                        <option value="PA">PA</option>
                        <option value="PR">PR</option>
                        <option value="RI">RI</option>
                        <option value="SC">SC</option>
                        <option value="SD">SD</option>
                        <option value="TN">TN</option>
                        <option value="TX">TX</option>
                        <option value="UT">UT</option>
                        <option value="VA">VA</option>
                        <option value="VI">VI</option>
                        <option value="VT">VT</option>
                        <option value="WA">WA</option>
                        <option value="WI">WI</option>
                        <option value="WV">WV</option>
                        <option value="WY">WY</option>
                      </select>
                    </div>

                    <div className="textFieldContainer">
                      <label htmlFor="zip">Zip</label>
                      <input
                        type=""
                        placeholder="Zip"
                        className="individualFirstName textfieldSmall"
                        id="zip"
                        {...register("zip")}
                      />
                    </div>
                  </div>

                  <div className="textFieldContainer ">
                    <label htmlFor="role">Role</label>
                    <select
                      className="individualFirstName textfieldSmall"
                      id="role"
                      {...register("role")}
                    >
                      {" "}
                      <option value="" disabled selected>
                        Role
                      </option>
                      <option value="OfficerInCharge">Officer In Charge</option>
                      <option value="Law Enforcement Witness">
                        Law Enforcement Witness
                      </option>
                      <option value="Evidence Tech">Evidence Tech</option>
                      <option value="Coroner">Coroner</option>
                      <option value="Deputy Coroner">Deputy Coroner</option>
                      <option value="Tox Person">Tox Person</option>
                      <option value="Case Agent">Case Agent</option>
                      <option value="Property Clerk">Property Clerk</option>
                      <option value="Fire Dept. Witness">
                        Fire Dept. Witness
                      </option>
                      <option value="Witness">Witness</option>
                      <option value="Victim">Victim</option>
                      <option value="Co-Victim">Co-Victim</option>
                      <option value="Representative">Representative</option>
                      <option value="Corpus">Corpus</option>
                      <option value="Corpus2">Corpus2</option>
                      <option value="Guardian">Guardian</option>
                      <option value="Co-Defendant">Co-Defendant</option>
                      <option value="JUV Co-Defendant">JUV Co-Defendant</option>
                      <option value="Confidential Informant">
                        Confidential Informant
                      </option>
                      <option value="ID Witness">ID Witness</option>
                      <option value="Custodian of Record">
                        Custodian of Record
                      </option>
                      <option value="Prescott House">Prescott House</option>
                      <option value="Special Witness">Special Witness</option>
                      <option value="Cross Complaint">Cross Complaint</option>
                    </select>
                  </div>

                  <div className="combineStart">
                    <div className="textFieldContainer marginRightLong">
                      <label htmlFor="race">Race</label>
                      <select
                        className="individualFirstName textfieldSmall"
                        id="race"
                        {...register("race")}
                      >
                        {" "}
                        <option value="" disabled selected>
                          Race
                        </option>
                        <option value="Asian">Asian</option>
                        <option value="Black">Black</option>
                        <option value="White">White</option>
                        <option value="Indian">Indian</option>
                        <option value="Hispanic">Hispanic</option>
                        <option value="Unknown">Unknown</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>

                    <div className="textFieldContainer marginRight">
                      <label htmlFor="sex">Sex</label>
                      <select
                        className="individualFirstName textfieldSmall"
                        id="sex"
                        {...register("sex")}
                      >
                        {" "}
                        <option value="" disabled selected>
                          Sex
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Unknown">Unknown</option>
                      </select>
                    </div>

                    <div className="textFieldContainer">
                      <label htmlFor="dob">DOB</label>
                      <input
                        type="date"
                        className="individualFirstName textfieldSmall"
                        id="dob"
                        {...register("dateOfBirth")}
                      />
                    </div>
                  </div>
                  <div className="combineStart">
                    <div className="textFieldContainer marginRight">
                      <label htmlFor="ssn">Social Security Number</label>
                      <input
                        placeholder="SSN"
                        className="individualFirstName textfieldSmall"
                        id="ssn"
                        {...register("ssn")}
                      />
                    </div>

                    <div className="textFieldContainer">
                      <label htmlFor="email">Email</label>
                      <input
                        type=""
                        placeholder="Email"
                        className="individualFirstName textField"
                        id="email"
                        {...register("email")}
                      />
                    </div>
                  </div>

                  <div className="combineStart">
                    <div className="textFieldContainer marginRight">
                      <label htmlFor="dlNo">Drivers License Number</label>
                      <input
                        type=""
                        placeholder="License Number"
                        className="individualFirstName textfieldSmall"
                        id="dlNo"
                        {...register("driversLicenseNumber")}
                      />
                    </div>

                    <div className="textFieldContainer marginRight">
                      <label htmlFor="dlState">Drivers License State</label>
                      <select
                        className="individualFirstName textfieldSmall"
                        id="dlState"
                        {...register("driversLicenseState")}
                      >
                        {" "}
                        <option value="" disabled selected>
                          State
                        </option>
                        <option value="AK">AK</option>
                        <option value="AL">AL</option>
                        <option value="AR">AR</option>
                        <option value="AS">AS</option>
                        <option value="AZ">AZ</option>
                        <option value="CA">CA</option>
                        <option value="CO">CO</option>
                        <option value="CT">CT</option>
                        <option value="DC">DC</option>
                        <option value="DE">DE</option>
                        <option value="FL">FL</option>
                        <option value="GA">GA</option>
                        <option value="GU">GU</option>
                        <option value="HI">HI</option>
                        <option value="IA">IA</option>
                        <option value="ID">ID</option>
                        <option value="IL">IL</option>
                        <option value="IN">IN</option>
                        <option value="KS">KS</option>
                        <option value="KY">KY</option>
                        <option value="LA">LA</option>
                        <option value="MA">MA</option>
                        <option value="MD">MD</option>
                        <option value="ME">ME</option>
                        <option value="MI">MI</option>
                        <option value="MN">MN</option>
                        <option value="MO">MO</option>
                        <option value="MS">MS</option>
                        <option value="MT">MT</option>
                        <option value="NC">NC</option>
                        <option value="ND">ND</option>
                        <option value="NE">NE</option>
                        <option value="NH">NH</option>
                        <option value="NJ">NJ</option>
                        <option value="NM">NM</option>
                        <option value="NV">NV</option>
                        <option value="NY">NY</option>
                        <option value="OH">OH</option>
                        <option value="OK">OK</option>
                        <option value="OR">OR</option>
                        <option value="PA">PA</option>
                        <option value="PR">PR</option>
                        <option value="RI">RI</option>
                        <option value="SC">SC</option>
                        <option value="SD">SD</option>
                        <option value="TN">TN</option>
                        <option value="TX">TX</option>
                        <option value="UT">UT</option>
                        <option value="VA">VA</option>
                        <option value="VI">VI</option>
                        <option value="VT">VT</option>
                        <option value="WA">WA</option>
                        <option value="WI">WI</option>
                        <option value="WV">WV</option>
                        <option value="WY">WY</option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>

              <div className="navigateSectionsContainer">
                <button
                  className="navigateSectionsButton"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </button>
                {isLoading ? (
                  <div className="loginLoaderContainer">
                    <RotatingLines
                      strokeColor="rgb(17,28,59)"
                      strokeWidth="5"
                      width="20"
                    />
                  </div>
                ) : (
                  ""
                )}

                <button
                  className="navigateSectionsButton"
                  type="submit"
                  onClick={() => onUpdateWitness()}
                >
                  Update
                </button>
              </div>
            </Dialog.Panel>
          </Dialog>
          <div className="witnessesFormContainer">
            <WitnessForm />
          </div>
          <div className="witnessesListContainer">
            <div className="witnessListHeader">
              <span>Witness List</span>
            </div>
            <div className="witnessListContainerForColoring">
              {witnesses ? witnesses : ""}
            </div>
          </div>
        </div>

        <div className="navigateSectionsContainer">
          <button className="navigateSectionsButton" onClick={() => back()}>
            Back
          </button>
          <button className="navigateSectionsButton" onClick={() => submit()}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default WitnessesView;
