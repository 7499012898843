import { Dialog } from "@headlessui/react";
import { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { RotatingLines } from "react-loader-spinner";
import { axiosClient } from "../../../axios";
import { useAppSelector } from "../../../redux/hook";
import "./styles.css";

const fileTypes = ["PDF", "JPG", "JPEG", "PNG", "MP4", "MOV", "HEIC"];

function DocumentsView() {
  const [uploadedFiles, setUploadedFiles] = useState(new Array());
  const [externalFileUrl, setExternalFileUrl] = useState("");
  let [isOpen, setIsOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [previewLink, setPreviewLink] = useState("");
  const [selectedDocumentTitle, setSelectedDocumentTitle] = useState("");
  const [selectedDocumentId, setSelectedDocumentId] = useState("");
  const [previousDocuments, setPreviousDocuments] = useState([]);
  const [fileCount, setFileCount] = useState(0);
  const handleChange = (files: File[]) => {
    // we create an array, even if user selects only one file.
    let arrayOfFiles: File[] = [];
    if (files instanceof File) {
      arrayOfFiles = [...uploadedFiles, files];
      setFileCount(arrayOfFiles.length);
    } else if (files instanceof FileList) {
      arrayOfFiles = [...uploadedFiles, ...Array.from(files)];
      setFileCount(arrayOfFiles.length);
    }

    setUploadedFiles(arrayOfFiles);
  };

  const caseId = useAppSelector((state) => state.case?.case?.id);

  const uploadDocument = async (file: File) => {
    setIsUploading(true);
    let formData = new FormData();
    formData.append("upload", file);

    try {
      const createVictim = await axiosClient.post(
        `/cases/${caseId}/uploads`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${window.sessionStorage.accessToken}`,
            "x-refresh-token": window.sessionStorage.refreshToken,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (event) => {
            const { loaded, total } = event;
          },
        }
      );
      if (createVictim.status === 200) {
        // setIsUploading(false);
        getDocuments();
        setIsLoading(false);
        setFileCount(0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const uploadAllDocuments = async () => {
    for (let file of uploadedFiles) {
      setIsUploading(true);
      await uploadDocument(file);
      setIsUploading(false);
    }
  };

  const back = () => {
    const userId = window.sessionStorage.userId;
    window.location.href = `/${userId}/witnesses`;
  };

  const getDocuments = async () => {
    const documents = await axiosClient.get(`/cases/${caseId}/uploads`, {
      headers: {
        Authorization: `Bearer ${window.sessionStorage.accessToken}`,
        "x-refresh-token": window.sessionStorage.refreshToken,
      },
    });
    if (documents.status === 200) {
      setPreviousDocuments(documents.data.data.files);
    }
  };

  const addExternalDocumentLink = async () => {
    setIsUploading(true);
    try {
      const createExternalLink = await axiosClient.post(
        `/cases/${caseId}/uploads/external`,
        { externalUrl: externalFileUrl },
        {
          headers: {
            Authorization: `Bearer ${window.sessionStorage.accessToken}`,
            "x-refresh-token": window.sessionStorage.refreshToken,
          },
        }
      );
      if (createExternalLink.status === 200) {
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsUploading(false);
      getDocuments();
    }
  };

  const initializeDelete = (document: any) => {
    setSelectedDocumentId(document.id);
    setSelectedDocumentTitle(document.filename);
    setIsOpen(true);
  };

  const cancelDelete = () => {
    setIsOpen(false);
    setSelectedDocumentId("");
    setSelectedDocumentTitle("");
  };

  const deleteSelectedDocument = async () => {
    setIsLoading(true);
    const cancelDoc = await axiosClient.delete(
      `/cases/${caseId}/uploads/${selectedDocumentId}`,
      {
        headers: {
          Authorization: `Bearer ${window.sessionStorage.accessToken}`,
          "x-refresh-token": window.sessionStorage.refreshToken,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (cancelDoc.status === 200) {
      setIsOpen(false);
      setIsLoading(false);
      getDocuments();
    }
  };

  const submit = () => {
    const userId = window.sessionStorage.userId;
    window.location.href = `/${userId}/officer`;
  };

  const retrieveDocumentPreview = async (id: string) => {
    try {
      const docPreview = await axiosClient.get(
        `/cases/${caseId}/uploads/${id}/preview`,
        {
          headers: {
            Authorization: `Bearer ${window.sessionStorage.accessToken}`,
            "x-refresh-token": window.sessionStorage.refreshToken,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (docPreview.status === 200) {
        // open dialog
        setIsPreviewOpen(true);
        setPreviewLink(docPreview.data.data.url);
      }
    } catch (error) {
      alert("Issue retrieving document preview");
      console.log(error);
    }
  };

  const renderPreviousDocuments = () => {
    const previousDocumentsTableItems = previousDocuments.map((d: any) => {
      const splitFileName = d.filename.split(".");
      const [fileType] = splitFileName.slice(-1);
      return (
        <div className="documentsTableItem" key={d.id}>
          <div className="documentsTableItemTitle">
            {d.filename ? (
              d.filename
            ) : (
              <a className="externalUrlAnchor" href={d.externalUrl}>
                {d.externalUrl}
              </a>
            )}
          </div>
          {d.filename ? (
            <div className="previewAndDeleteContainer">
              {fileType !== "mp4" && fileType !== "MOV" ? (
                <span
                  onClick={() => retrieveDocumentPreview(d.id)}
                  className="viewPreview"
                >
                  View Preview
                </span>
              ) : (
                <span>No Preview Available</span>
              )}
            </div>
          ) : (
            ""
          )}
          <i
            className="fa-solid fa-delete-left documentsTableItemDelete"
            onClick={() => initializeDelete(d)}
          ></i>
        </div>
      );
    });
    return previousDocumentsTableItems;
  };

  useEffect(() => {
    getDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={isOpen ? "blur FormWrapper" : "FormWrapper"}>
      <div className="fullWrapper">
        <div className="IncidentHeader">Documents</div>
        <div className="uploaderAndTableContainer">
          {" "}
          <div className="uploaderContainer">
            <FileUploader
              classes="marginAuto newBorder"
              handleChange={handleChange}
              name="file"
              types={fileTypes}
              multiple={true}
            />
            <div>
              {fileCount > 0 && (
                <div className="fileCountText">
                  {fileCount} file{fileCount > 1 ? "s" : ""} ready to upload
                </div>
              )}
              <button
                className="uploadDocumentButton"
                onClick={uploadAllDocuments}
              >
                Upload
              </button>
            </div>
          </div>
          <div className="fileTypesDescription">
            File Types: PDF, JPG, JPEG, PNG, MP4, MOV, HEIC
          </div>
          <div className="externalDocumentUploadHeader ExternalDocumentsUploadheader">
            Upload External Document
          </div>
          <div className="addExternalUrlContainer">
            <input
              value={externalFileUrl}
              className="externalUrlInput"
              placeholder="External Document Link"
              onChange={(e) => {
                setExternalFileUrl(e.target.value);
              }}
            />
            <button
              className="uploadDocumentButton externalDocumentButton"
              disabled={!externalFileUrl}
              onClick={addExternalDocumentLink}
            >
              {" "}
              Add External Document
            </button>
          </div>
          <div>
            {isUploading && (
              <div className="loginLoaderContainer">
                <RotatingLines
                  strokeColor="rgb(17,28,59)"
                  strokeWidth="5"
                  width="20"
                />
              </div>
            )}
          </div>
          <div className="previouslyUploadedContainer">
            <div className="previouslyUploadedDocumentsTitle">
              Previously Uploaded
            </div>
            {previousDocuments ? renderPreviousDocuments() : ""}
          </div>
          <Dialog
            className="dialogPreview"
            open={isPreviewOpen}
            onClose={() => setIsPreviewOpen(false)}
          >
            <Dialog.Panel>
              <Dialog.Title>Preview</Dialog.Title>
              <Dialog.Description></Dialog.Description>
              <div className="structureImage">
                <img className="previewImageContainer" src={previewLink}></img>
              </div>

              <button
                className="previewCancelButton"
                onClick={() => setIsPreviewOpen(false)}
              >
                Close
              </button>
              <div className="isLoadingAtDocs">
                {isLoading && (
                  <div className="loginLoaderContainer">
                    <RotatingLines
                      strokeColor="rgb(17,28,59)"
                      strokeWidth="5"
                      width="20"
                    />
                  </div>
                )}
              </div>
            </Dialog.Panel>
          </Dialog>
          <Dialog
            className="dialogOfficer"
            open={isOpen}
            onClose={() => setIsOpen(false)}
          >
            <Dialog.Panel>
              <Dialog.Title>Delete document</Dialog.Title>
              <Dialog.Description></Dialog.Description>
              <p>
                Do you want to delete this document: {selectedDocumentTitle}?
              </p>
              <button
                className="deleteCancelButton"
                onClick={() => cancelDelete()}
              >
                Cancel
              </button>
              <button
                className="deleteConfirmButton"
                onClick={() => deleteSelectedDocument()}
              >
                Yes
              </button>
              <div className="isLoadingAtDocs">
                {isLoading && (
                  <div className="loginLoaderContainer">
                    <RotatingLines
                      strokeColor="rgb(17,28,59)"
                      strokeWidth="5"
                      width="20"
                    />
                  </div>
                )}
              </div>
            </Dialog.Panel>
          </Dialog>
        </div>
        <div className="navigateSectionsContainer">
          <button className="navigateSectionsButton" onClick={() => back()}>
            Back
          </button>
          <button className="navigateSectionsButton" onClick={() => submit()}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default DocumentsView;
