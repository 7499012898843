import Login from "../Login/Login";
import "./styles.css";

function Landing() {
  return (
    <div className="Landing">
      <div className="Seal"></div>
      <div className="loginContainer">
        <Login />
      </div>
    </div>
  );
}

export default Landing;
