import { zodResolver } from "@hookform/resolvers/zod";
import { LoginInput, loginSchema } from "@ms1da/server";
import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { RotatingLines } from "react-loader-spinner";
import { axiosClient } from "../../../axios";
import "./styles.css";

function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { register, handleSubmit, formState } = useForm<LoginInput>({
    resolver: zodResolver(loginSchema),
  });
  const getUserInfo = async () => {
    try {
      let userInfo = await axiosClient.get("/users/me", {
        headers: {
          Authorization: `Bearer ${window.sessionStorage.accessToken}`,
          "x-refresh-token": window.sessionStorage.refreshToken,
        },
      });
      if (userInfo.status === 200) {
        window.sessionStorage.setItem(
          "userFirstName",
          userInfo.data.data.user.firstName
        );
        window.location.href = "/dashboard";
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    setErrorMessage("");

    try {
      let loginResult = await axiosClient.post("/sessions", {
        ...data,
      });

      if (loginResult.status === 201) {
        window.sessionStorage.setItem(
          "accessToken",
          loginResult.data.data.accessToken
        );
        window.sessionStorage.setItem(
          "refreshToken",
          loginResult.data.data.refreshToken
        );
        window.sessionStorage.setItem("userId", loginResult.data.data.userId);

        getUserInfo();
      }
    } catch (error) {
      setIsLoading(false);
      if (error instanceof axios.AxiosError) {
        if (error.response?.statusText === "Not Found") {
          setErrorMessage("Account not Found");
        }
        if (error.response?.data.message === "Invalid password") {
          setErrorMessage("Invalid Password");
        }
      }
    }
  });

  const { errors } = formState;

  return (
    <div className="Login">
      <div className="loginFormWrapper">
        <div className="formContainer">
          <div className="inputContainer" id="loginForm">
            <div id="loginFormHeader">
              <h3 id="loginFormTitle">Police Portal Login</h3>
              <p className="loginFormSubTitle">
                Please enter your login information to continue
              </p>
            </div>
            <form onSubmit={onSubmit}>
              <div className="splitLoginAndRequest">
                <div id="loginFormBody">
                  <input
                    className="loginInput"
                    id="username"
                    {...register("email")}
                  />

                  <input
                    className="loginInput"
                    id="password"
                    {...register("password")}
                    type="password"
                  />

                  <button className="loginButton loginPadding" type="submit">
                    Login
                  </button>
                </div>

                <div className="requestAccessButtonWrapper">
                  <button
                    className="requestAccessButton"
                    type="button"
                    onClick={() => (window.location.href = "/requestAccess")}
                  >
                    Request Access
                  </button>
                </div>
              </div>
              <div
                className="forgotPasswordButton"
                onClick={() => (window.location.href = "/forgotPassword")}
              >
                Forgot Password
              </div>
              <div className="login-invalid-feedback-container">
                <div className="invalid-feedback">{errors.email?.message}</div>
                <div className="invalid-feedback">
                  {errors.password?.message}
                </div>
                {isLoading && (
                  <div className="loginLoaderContainer">
                    <RotatingLines
                      strokeColor="rgb(17,28,59)"
                      strokeWidth="5"
                      width="20"
                    />
                  </div>
                )}
                {errorMessage ? (
                  <div className="invalid-feedback">{errorMessage}</div>
                ) : (
                  ""
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
