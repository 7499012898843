import { z } from "zod";
// import { emailSchema } from "./globals.schema";
import { CaseResponse } from "./response.schema";

export const victimSchema = z.object({
  type: z.string().optional(),
  firstName: z.string().optional(),
  middleName: z.string().optional(),
  lastName: z.string().optional(),
  phoneHome: z.string().optional(),
  phoneCell: z.string().optional(),
  race: z.string().optional(),
  sex: z.string().optional(),
  dateOfBirth: z.string().optional(),
  ssn: z.string().optional(),
  address: z.string().optional(),
  apartmentNumber: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  zip: z.string().optional(),
  email: z.string().optional(),
  driversLicenseNumber: z.string().optional(),
  driversLicenseState: z.string().optional(),
  hasPriors: z.boolean().optional().default(false),
  corporationNameAndRepresentative: z.string().optional(),
  isOwedRestitution: z.boolean().optional().default(false),
  restitutionAmount: z.string().optional(),
});

const params = {
  params: z.object({
    caseId: z.string({ required_error: "caseId is required" }),
  }),
};

const payload = {
  body: victimSchema,
};

export const updateVictimRequest = z.object({
  ...params,
  ...payload,
});

export type Victim = z.TypeOf<typeof victimSchema>;

export type UpdateVictimRequest = z.TypeOf<typeof updateVictimRequest>;
export type UpdateVictimInput = UpdateVictimRequest["body"];
export type UpdateVictimResponse = CaseResponse;
