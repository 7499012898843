import { UpdateIncidentInput } from "@ms1da/server";
import { useFormContext } from "react-hook-form";
import "./styles.css";

function PropertyIncidentOptionsForm() {
  const { register } = useFormContext<UpdateIncidentInput>();
  return (
    <div className="PropertyIncidentOptionsForm">
      <div className="combineStart">
        <div className="textFieldContainer propertyTaken">
          <label htmlFor="propertyTaken">Property Taken</label>
          <input
            className="individualFirstName textField"
            placeholder="Property Taken"
            id="propertyTaken"
            {...register("propertyTaken")}
          />
        </div>

        <div className="textFieldContainer">
          <label htmlFor="propertyValue">Property Value</label>
          <input
            className="individualFirstName textField"
            placeholder="Property Value"
            id="propertyValue"
            type={"number"}
            {...register("propertyValue")}
          />
        </div>
      </div>
    </div>
  );
}

export default PropertyIncidentOptionsForm;
