import "./styles.css";

function CreateRequestAccessForm() {
  return (
    <div>
      <div className="CreateRequestAccessFormWrapper">
        <div className="CreateRequestAccessForm">
          <div className="textFieldContainer">
            <label htmlFor="requestAccessFirstName">First Name</label>
            <input
              type=""
              className="individualFirstName textField"
              id="requestAccessFirstName"
            />
          </div>

          <div className="textFieldContainer">
            <label htmlFor="requestAccessLastName">Last Name</label>
            <input
              type=""
              className="individualFirstName textField"
              id="requestAccessLastName"
            />
          </div>

          <div className="textFieldContainer">
            <label htmlFor="requestAccessEmail">Email</label>
            <input
              type=""
              className="individualFirstName textField"
              id="requestAccessEmail"
            />
          </div>

          <div className="textFieldContainer">
            <label htmlFor="requestAccessPhoneNumber">Phone Number</label>
            <input
              type=""
              className="individualFirstName textField"
              id="requestAccessPhoneNumber"
            />
          </div>

          <div className="textFieldContainer">
            <label htmlFor="requestAccessAgency">Agency</label>
            <input
              type=""
              className="individualFirstName textField"
              id="requestAccessAgency"
            />
          </div>

          <div className="textFieldContainer">
            <label htmlFor="requestAccessPosition">Position</label>
            <input
              type=""
              className="individualFirstName textField"
              id="requestAccessPosition"
            />
          </div>

          <div className="textFieldContainer">
            <label htmlFor="requestAccessBadgeNumber">Badge No.</label>
            <input
              type=""
              className="individualFirstName textField"
              id="requestAccessBadgeNumber"
            />
          </div>

          <div className="textFieldContainer">
            <label htmlFor="requestAccessPassword">Password</label>
            <input
              type=""
              className="individualFirstName textField"
              id="requestAccessPassword"
            />
          </div>

          <div className="textFieldContainer">
            <label htmlFor="requestAccessConfirmPassword">
              Confirm Password
            </label>
            <input
              type=""
              className="individualFirstName textField"
              id="requestAccessConfirmPassword"
            />
          </div>
        </div>
      </div>

      <div className="RequestAccessBtnContainer">
        <div className="RequestAccessNextAndBackBtnWrapper">
          {/* <button className='RequestAccessBackBtn'>Back</button> */}
          {/* <button className='RequestAccessNextBtn nextBtnGlobal nextBtn' onClick={this.handleSubmit}>Submit</button> */}
        </div>
      </div>
    </div>
  );
}

export default CreateRequestAccessForm;
