// @ts-nocheck
import { Victim } from "@ms1da/server";
import { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { RotatingLines } from "react-loader-spinner";
import { axiosClient } from "../../../axios";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { setCase, setVictim } from "../../../redux/slices/CaseSlice";
import BusinessVictimOptionsForm from "./BusinessVictimOptionsForm";
import IndividualVictimOptionsForm from "./IndividualVictimOptionsForm";
import StateVictimOptionsForm from "./StateVictimOptionsForm";
import "./styles.css";

function CreateVictimForm() {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const caseData = useAppSelector((state) => state.case?.case);

  const [selectedVictimType, setSelectedVictimType] = useState<
    string | undefined
  >("");
  const victimTypeSelection = useRef<HTMLSelectElement>(null);

  const checkForVictimTypeSelection = () => {
    if (victimTypeSelection === null) {
    } else {
      dispatch(
        setCase({
          ...(caseData as Case),
          crimeType: victimTypeSelection.current?.value || "",
        })
      );
      setSelectedVictimType(victimTypeSelection.current?.value);
      return victimTypeSelection.current?.value;
    }
  };

  const methods = useForm<Incident>();
  const caseId = useAppSelector((state) => state.case?.case?.id);

  const back = () => {
    const userId = window.sessionStorage.userId;
    window.location.href = `/${userId}/suspect`;
  };

  const onSubmitVictim = methods.handleSubmit(async (data) => {
    setIsLoading(true);
    if (data.dateOfBirth) {
      let date = data.dateOfBirth.split("-");
      const structuredDate = `${date[1]}/${date[2]}/${date[0]}`;
      data.dateOfBirth = structuredDate;
    }

    if (victimTypeSelection.current?.value) {
      const dataWithVictimType = {
        ...data,
        victimType: victimTypeSelection.current?.value,
      };
      dispatch(setVictim(dataWithVictimType));
    } else {
      dispatch(setVictim(data));
    }

    let dataForDBWithVictimType;

    if (data.ssn) {
      dataForDBWithVictimType = {
        ...data,
        type: selectedVictimType,
        ssn: data.ssn.toString(),
      };
    } else {
      dataForDBWithVictimType = {
        ...data,
        type: selectedVictimType,
      };
    }

    try {
      const createVictim = await axiosClient.put(
        `/cases/${caseId}/victim`,
        dataForDBWithVictimType,
        {
          headers: {
            Authorization: `Bearer ${window.sessionStorage.accessToken}`,
            "x-refresh-token": window.sessionStorage.refreshToken,
          },
        }
      );
      if (createVictim.status === 200) {
        const userId = window.sessionStorage.userId;
        setIsLoading(false);
        window.location.href = `/${userId}/witnesses`;
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  });

  const setVictimData = (victimData: Victim) => {
    let formattedDate = "";
    if (victimData.dateOfBirth?.split("/")) {
      const splitDate = victimData.dateOfBirth?.split("/");
      formattedDate = `${splitDate[2]}-${splitDate[0]}-${splitDate[1]}`;
    }
    methods.reset({
      street: victimData.street,
      apartmentNumber: victimData.apartmentNumber,
      city: victimData.city,
      state: victimData.state,
      zip: victimData.zip,
      charge: victimData.charge,
      date: victimData.date,
      time: victimData.time,
      firstName: victimData.firstName,
      middleName: victimData.middleName,
      lastName: victimData.lastName,
      race: victimData.race,
      sex: victimData.sex,
      address: victimData.address,
      dateOfBirth: formattedDate,
      ssn: victimData.ssn,
      phoneHome: victimData.phoneHome,
      phoneCell: victimData.phoneCell,
      email: victimData.email,
      hasPriors: victimData.hasPriors,
      isOwedRestitution: victimData.isOwedRestitution,
      restitutionAmount: victimData.restitutionAmount,
    });

    if (victimData.type) {
      setSelectedVictimType(victimData.type);
      victimTypeSelection.current.value = victimData.type;
    }
  };

  const victimData = useAppSelector((state) => state.case?.case?.victim);

  useEffect(() => {
    if (victimData && victimData) {
      if (victimData.victimType) {
        setSelectedVictimType(victimData.victimType);
        victimTypeSelection.current.value = victimData.victimType;
      }
      setVictimData(victimData);
    }
  }, []);

  return (
    <FormProvider {...methods}>
      <div className="FormWrapper">
        <div className="fullWrapper">
          <div className="IncidentHeader">Victim</div>
          <div className="textFieldContainer">
            <label htmlFor="victimType">Victim Type</label>
            <select
              className="individualRace textField"
              id="victimType"
              ref={victimTypeSelection}
              onChange={() => checkForVictimTypeSelection()}
            >
              <option value="" disabled selected>
                Select Victim
              </option>
              <option value="Business Victim">Business Victim</option>
              <option value="State Victim">State Victim</option>
              <option value="Individual Victim">Individual Victim</option>
            </select>
          </div>
          {selectedVictimType === "Business Victim" ? (
            <BusinessVictimOptionsForm />
          ) : (
            ""
          )}
          {selectedVictimType === "State Victim" ? (
            <StateVictimOptionsForm />
          ) : (
            ""
          )}
          {selectedVictimType === "Individual Victim" ? (
            <IndividualVictimOptionsForm />
          ) : (
            ""
          )}

          <div className="navigateSectionsContainer">
            <button className="navigateSectionsButton" onClick={() => back()}>
              Back
            </button>
            {isLoading && (
              <div className="loginLoaderContainer">
                <RotatingLines
                  strokeColor="rgb(17,28,59)"
                  strokeWidth="5"
                  width="20"
                />
              </div>
            )}
            <button
              className="navigateSectionsButton"
              onClick={() => onSubmitVictim()}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </FormProvider>
  );
}

export default CreateVictimForm;
