import { z } from "zod";
import { CaseResponse } from "./response.schema";

export const defendantSchema = z.object({
  firstName: z.string({ required_error: "Defendant first name is required" }),
  middleName: z.string({ required_error: "Defendant middle name is required" }),
  lastName: z.string({ required_error: "Defendant last name is required" }),
  alias: z.string().optional(),
  relationToVictim: z.string().optional(),
  address: z.string().optional(),
  apartmentNumber: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  zip: z.string().optional(),
  race: z.string().optional(),
  sex: z.string().optional(),
  dateOfBirth: z.string().optional(),
  hairColor: z.string().optional(),
  eyeColor: z.string().optional(),
  heightFeet: z.string().optional(),
  heightInches: z.string().optional(),
  weight: z.string().optional(),
  ssn: z.string().optional(),
  isSuspectInCustody: z.boolean().optional().default(false),
  hasPriorArrests: z.boolean().optional().default(false),
  custodyLocation: z.string().optional(),
  coDefendantName: z.string().optional(),
  driversLicenseNumber: z.string().optional(),
  driversLicenseState: z.string().optional(),
});

const params = {
  params: z.object({
    caseId: z.string({ required_error: "caseId is required" }),
  }),
};

const payload = {
  body: defendantSchema,
};

export const updateDefendantRequest = z.object({
  ...params,
  ...payload,
});

export type Defendant = z.TypeOf<typeof defendantSchema>;

export type UpdateDefendantInput = UpdateDefendantRequest["body"];
export type UpdateDefendantRequest = z.TypeOf<typeof updateDefendantRequest>;
export type UpdateDefendantResponse = CaseResponse;
