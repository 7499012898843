import { Witness as W } from "@ms1da/server";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

interface WitnessList {
  witnesses: W[];
}

const initialState: WitnessList = { witnesses: [] };

export const WitnessListSlice = createSlice({
  name: "witnessList",
  initialState,
  reducers: {
    setWitness: (state, action: PayloadAction<W>) => {
      state.witnesses = [...state.witnesses, action.payload];
    },
  },
});

export const { setWitness } = WitnessListSlice.actions;

export const getWitnesses = (state: RootState) => state.witnesses;

export default WitnessListSlice.reducer;
