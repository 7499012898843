import { Case, CreateCaseInput } from "@ms1da/server";
import { useEffect, useRef, useState } from "react";
import { axiosClient } from "../../../axios";
import { useAppDispatch } from "../../../redux/hook";
import { clearResults, setCase } from "../../../redux/slices/CaseSlice";
import "./styles.css";

function Dashboard() {
  const dispatch = useAppDispatch();
  const [userCases, setUserCases] = useState("");
  const [filteredCases, setFilteredCases] = useState("");
  const [query, setquery] = useState("");
  const casesSearchRef = useRef("");

  const createCase = async () => {
    dispatch(clearResults());

    const caseData: CreateCaseInput = {
      officerId: window.sessionStorage.userId,
      officerCaseNumber: "",
      submittedBy: "",
      // test setting here
      status: "Incomplete",
      ccNo: "",
      dcNo: "",
      narrative: "",
    };
    try {
      const caseCreation = await axiosClient.post(
        "/cases",
        {
          ...caseData,
        },
        {
          headers: {
            Authorization: `Bearer ${window.sessionStorage.accessToken}`,
            "x-refresh-token": window.sessionStorage.refreshToken,
          },
        }
      );
      if (caseCreation.status === 201) {
        let editedCaseData = caseCreation.data.data.case;
        editedCaseData.officer.personSigningWarrant = "";
        dispatch(setCase({ ...editedCaseData, crimeType: "" }));
        const userId = window.sessionStorage.userId;

        window.location.href = `/${userId}/incident`;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderCaseTitle = (Case: any) => {
    const firstName = Case.defendant.firstName;
    const middleNameInitial = Case.defendant.middleName.charAt(0);
    const lastName = Case.defendant.lastName;
    const charge = Case.incident.charge;
    const date = new Date(Case.createdAt);
    const month = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();
    const fullDate = `${month + 1}/${day}/${year}`;

    return (
      <div className="dashboardLineItemContainer">
        <div className="dashboardLineItemCreatedAt dashboardCaseTitle">
          {fullDate}
        </div>
        <div className="dashboardDivider dashboardCaseTitle">-</div>
        <div className="dashboardNameContainer">
          <div className="dashboardCaseTitle dashboardVictimFirstName">
            {firstName ? firstName : "Defendant Name"}
          </div>
          <div className="dashboardCaseTitle dashboardVictimFirstName">
            {middleNameInitial ? middleNameInitial : ""}
          </div>
          <div className="dashboardCaseTitle">{lastName ? lastName : ""}</div>
        </div>
        <div className="dashboardDivider dashboardCaseTitle">-</div>
        <div className="dashboardLineItemCharge">
          {charge ? charge : "Charge"}
        </div>
      </div>
    );
  };

  const setCaseData = (caseData: Case) => {
    dispatch(
      setCase({ ...caseData, crimeType: caseData?.incident.crimeType || "" })
    );
    const userId = window.sessionStorage.userId;
    window.location.href = `/${userId}/incident`;
  };

  const getCaseById = async () => {
    let userId = window.sessionStorage.userId;
    let caseData: Array<any> = [];
    const casesById = await axiosClient.get(`/cases/`, {
      headers: {
        Authorization: `Bearer ${window.sessionStorage.accessToken}`,
        "x-refresh-token": window.sessionStorage.refreshToken,
      },
    });

    let casesByUserId: any = [];
    if (casesById.data.data.cases.length > 0) {
      casesById.data.data.cases.forEach((c: any) => {
        if (c.officerId === userId) {
          caseData = [...caseData, c];
          casesByUserId.push(
            <div className="dashboardCaseContainer" key={c.id}>
              {renderCaseTitle(c)}
              <div className="statusAndEditContainer">
                <div className="dashboardStatus toolTipWrap">
                  {c.status ? c.status : "Incomplete"}
                  {c.status === "Rejected" ? (
                    <div className="toolTipContent">
                      {c.rejectedReason ? c.rejectedReason : "No Reason Given"}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className="dashboardEditButton"
                  onClick={() => setCaseData(c)}
                >
                  Edit
                </div>
              </div>
            </div>
          );
        }
      });
    } else {
      casesByUserId.push(
        <div className="dashboardCaseContainer">
          <div className="noCasesContainer">
            {" "}
            Cases you create will be listed here
          </div>
        </div>
      );
    }

    if (casesByUserId) {
      casesByUserId.reverse();
      // @ts-ignore
      setUserCases(caseData);
      setFilteredCases(casesByUserId);
    }
  };

  useEffect(() => {
    getCaseById();
  }, []);

  const renderCaseFromFilter = (results: any) => {
    const filteredResults = results.reverse().map((c: any) => {
      return (
        <div className="dashboardCaseContainer" key={c.id}>
          {renderCaseTitle(c)}
          <div className="statusAndEditContainer">
            <div className="dashboardStatus">
              {c.status ? c.status : "Incomplete"}
            </div>
            <div className="dashboardEditButton" onClick={() => setCaseData(c)}>
              Edit
            </div>
          </div>
        </div>
      );
    });
    setFilteredCases(filteredResults);
  };

  const filterCases = (e: Event) => {
    // @ts-ignore
    setquery(e.target.value);
    // @ts-ignore
    if (userCases) {
      // @ts-ignore
      const results = userCases.filter((r) => {
        // @ts-ignore
        if (e.target.value === "") return userCases;

        const date = new Date(r.createdAt);
        const month = date.getMonth();
        const day = date.getDate();
        const year = date.getFullYear();
        const fullDate = `${month + 1}/${day}/${year}`;
        const fullName = `${r.defendant.firstName} ${r.defendant.middleName} ${r.defendant.lastName}`;
        if (
          // @ts-ignore
          fullDate.includes(e?.target?.value.toLowerCase()) ||
          // @ts-ignore
          fullName.toLowerCase().includes(e.target.value.toLowerCase())
        ) {
          return r;
        }
      });
      // @ts-ignore
      setquery(e.target.value);
      renderCaseFromFilter(results);
    }
  };

  return (
    <div className="dashboard">
      <div className="caseListContainer">
        <div className="caseListWrapper">
          <div className="caseListHeader">Dashboard</div>
          <div className="caseList">
            <input
              // @ts-ignore
              ref={casesSearchRef}
              className="caseSearchBar"
              placeholder={"Case Search"}
              value={query}
              // @ts-ignore
              onChange={filterCases}
            />
            {filteredCases ? <>{filteredCases}</> : ""}
          </div>
          <div className="newCaseButtonContainer">
            <button
              className="createNewCaseButton"
              onClick={() => {
                createCase();
              }}
            >
              Create New Case
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
