import { z } from "zod";
import { Case } from "./case.schema";
import { Witness } from "./witness.schema";

const baseResponseSchema = z.object({
  ok: z.boolean().default(false),
  message: z.string(),
  data: z.object({}).optional(),
});

export type BaseResponse = z.TypeOf<typeof baseResponseSchema>;

export type CaseResponse = BaseResponse & { data: { case: Case | null } };
export type CasesResponse = BaseResponse & { data: { cases: Array<Case> } };
export type WitnessResponse = BaseResponse & {
  data: { witness: Witness | null };
};
