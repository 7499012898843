import { z } from "zod";
import { BaseResponse } from "./response.schema";

const sessionSchema = z.object({
  id: z.string(),
  createdAt: z.number(),
  updatedAt: z.number(),
  userId: z.string(),
  userAgent: z.string().optional(),
  _recordId: z.number(),
});

export const loginSchema = z.object({
  email: z
    .string({ required_error: "email is required" })
    .email({ message: "invalid email format" }),
  password: z
    .string({ required_error: "password is required" })
    .min(8, "password is required"),
});

export const loginRequest = z.object({
  body: loginSchema,
});

export type LoginInput = z.TypeOf<typeof loginSchema>;
export type LoginRequest = z.TypeOf<typeof loginRequest>;
export type LoginResponse = BaseResponse & {
  data: {
    accessToken: string | null;
    refreshToken: string | null;
    userId: string | null;
  };
};

export type Session = z.TypeOf<typeof sessionSchema>;
