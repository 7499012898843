import { Incident, UpdateIncidentInput } from "@ms1da/server";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useAppSelector } from "../../../../redux/hook";

function OtherIncidentOptionsForm() {
  const { register, reset } = useFormContext<UpdateIncidentInput>();

  const setIncidentData = (incidentData: Incident) => {
    reset({
      crimeDetails: incidentData.crimeDetails,
    });
  };

  const incidentData = useAppSelector((state) => state.case?.case?.incident);

  useEffect(() => {
    if (incidentData && incidentData) {
      setIncidentData(incidentData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="OtherIncidentOptionsForm">
      <div className="textFieldContainer">
        <label htmlFor="crimeDetails">Crime Details</label>
        <input
          className="individualFirstName textField"
          placeholder="Crime Details"
          id="crimeDetails"
          {...register("crimeDetails")}
        />
      </div>
    </div>
  );
}

export default OtherIncidentOptionsForm;
