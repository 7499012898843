import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
// import { Incident } from "../../Types/index";
import { Incident } from "@ms1da/server";

interface IncidentState {
  incident: Incident | null;
}

const initialState: IncidentState = { incident: null };

export const IncidentSlice = createSlice({
  name: "incident",
  initialState,
  reducers: {
    // changed from setIncident to setIncidentsss for Case testing
    setIncidents: (state, action: PayloadAction<Incident>) => {
      state.incident = action.payload;
    },
  },
});

export const { setIncidents } = IncidentSlice.actions;

// export const getIncident = (state: RootState) => state.incident;

export default IncidentSlice.reducer;
