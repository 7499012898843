import { z } from "zod";
import { BaseResponse } from "./response.schema";

export const uploadSchema = z.object({
  id: z.string(),
  _recordId: z.number(),
  externalId: z.string(),
  caseId: z.string(),
  filename: z.string(),
  notes: z.string(),
  status: z.string(),
  externalUrl: z.string().nullable(),
});

const baseParams = {
  params: z.object({
    caseId: z.string({ required_error: "caseId is required" }),
  }),
};

const params = {
  params: baseParams.params.extend({
    uploadId: z.string({ required_error: "uploadId is required" }),
  }),
};

export const addExternalFileSchema = z.object({
  ...baseParams,
  body: z.object({
    externalUrl: z.string().url(),
  }),
});

export const createUploadSchema = z.object({
  ...baseParams,
});

export const deleteUploadSchema = z.object({
  ...params,
});

export const previewUploadSchema = z.object({
  ...params,
});

export const getUploadsByCaseIdSchema = z.object({
  ...baseParams,
});

export type AddExternalFileRequest = z.TypeOf<typeof addExternalFileSchema>;

export type Upload = z.TypeOf<typeof uploadSchema>;

export type CreateUploadRequest = z.TypeOf<typeof createUploadSchema>;
export type CreateUploadResponse = BaseResponse;

export type DeleteUploadRequest = z.TypeOf<typeof deleteUploadSchema>;
export type DeleteUploadResponse = BaseResponse;

export type PreviewUploadRequest = z.TypeOf<typeof previewUploadSchema>;
export type PreviewUploadResponse = BaseResponse;

export type GetUploadsByCaseIdRequest = z.TypeOf<
  typeof getUploadsByCaseIdSchema
>;
export type GetUploadsByCaseIdResponse = BaseResponse;
