import { z } from "zod";

export const emailSchema = z
  .string({ required_error: "email is required" })
  .email({ message: "invalid email format" });

export const passwordSchema = z
  .string({ required_error: "password is required" })
  .min(6, "password must be a minumum of 6 characters");

export const confirmPasswordSchema = z
  .string({ required_error: "confirmPassword is required" })
  .min(6, "confirmPassword must be a minumum of 6 characters");
